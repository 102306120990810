
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Direction } from '@angular/cdk/bidi';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getEventSubmenu } from 'src/app/core/state/general-state/general.selectors';
import { setClient, setAccountIndex } from 'src/app/core/state/auth-state/auth.actions';
import { LoginService } from 'src/app/services/login/login.service';
import { UserIdleService } from 'angular-user-idle';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { getLoggedUser, getSettings } from 'src/app/core/state/auth-state/auth.selectors';
import { take } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import * as moment from 'moment';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  dir = 'ltr' as Direction;
  green = false;
  blue = false;
  dark = false;
  minisidebar = false;
  boxed = false;
  danger = false;
  showHide = false;
  horizontal = false;
  url = '';
  sidebarOpened = false;
  status = false;
  actionSubmenu = false;
  eventSubmenu$: Observable<string>;
  eventSubmenuSub: Subscription = new Subscription();
  image: any = null;
  name: string = "";
  count: number = 60;
  duration: string = '';
  interval: any;
  hasTimeoutSession: boolean = false;
  logo: any;
  subscription: Subscription = new Subscription();

  public showSearch = false;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  clickEvent() {
    this.status = !this.status;
  }

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private store: Store,
    private loginService: LoginService,
    private userIdle: UserIdleService,
    private settingsService: SettingsService,
    private tenantService: TenantService,
    private authService: AuthService,
    private loadingService: LoadingService

  ) {
    this.tenantService.getClientConfig(true);
    this.eventSubmenu$ = this.store.select(getEventSubmenu);
    // deixa o menu expanded / closed em automático
    this.mobileQuery = media.matchMedia('(min-width: 960px)');
    // this.mobileQuery = media.matchMedia('(min-width: 0px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.store.select(getLoggedUser).subscribe(client => {
      if (client) {
        const namesSplited = client.name.split(" ") ?? [];

        this.name = client.name ? `${namesSplited[0]} ${namesSplited[namesSplited.length - 1]}` : ""
      }
    });

    this.loginService.getClientProfile().subscribe(x => {
      let reader = new FileReader();
      if (x.type == 'image/png' || x.type == 'image/jpeg') {
        reader.addEventListener("load", () => {
          this.image = reader.result;
        })
        if (x) {
          reader.readAsDataURL(x);
        }
      }
    });

    this.getClientLogo();
  }

  ngOnInit() {
    this.userIdle.startWatching();
    this.eventSubmenuSub = this.eventSubmenu$.subscribe((eventName) => {
      if (eventName === 'opened') {
        this.actionSubmenu = true;
      } else {
        this.actionSubmenu = false;
      }
    });

    this.eventSubmenuSub.add(this.userIdle.onTimerStart().subscribe());

    // Start watch when time is up.
    this.eventSubmenuSub.add(this.userIdle.onTimeout().subscribe(() => {
      this.hasTimeoutSession = true;
      this.userIdle.stopWatching();
      this.getTime();
      // Sua sessão foi encerrada por inatividade.
    }));
  }

  getClientLogo(): void {
    this.subscription.add(this.settingsService.getLogo().subscribe(
      (result) => {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          this.logo = reader.result;
        })
        if (result) {
          reader.readAsDataURL(result);
        }
      },
    ))
  }

  continueSession() {
    clearInterval(this.interval);
    this.count = 60;
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
    this.hasTimeoutSession = false;
  }

  getTime() {
    this.interval = setInterval(() => {
      --this.count;

      if (this.count == 0) {
        this.count = 0;
        clearInterval(this.interval);
        this.hasTimeoutSession = true;
        this.logout();
        this.store.dispatch(setAccountIndex({ account: "" }))
        this.loginService.loginTimeSubject.next(true);
      }
    }, 1000);
  }

  formatText(time: number): string {
    let min = Math.trunc(time / 60);
    let sec = Math.trunc(time % 60);
    let minutes = min < 10 ? '0' + min : min;
    let seconds = sec < 10 ? '0' + sec : sec;

    let duration = `${minutes}:${seconds}`;

    return duration
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.eventSubmenuSub.unsubscribe();
    this.subscription.unsubscribe();
  }

  changeSideBar(event: boolean) {
    this.minisidebar = event;
  }

  changeMenu(event: boolean) {
  }

  logout() {
    this.loginService.logout();
  }

  downloadIR() {
    this.loadingService.initializeLoading();
    this.subscription.add(this.settingsService.getIncomeReports(moment().subtract(1, 'year').year().toString()).subscribe(
      (result) => {
        const linkSource = 'data:application/pdf;base64,' + result.contentFile;
        const downloadLink = document.createElement("a");
        const fileName = "informe-rendimentos-" + moment().subtract(1, 'year').year().toString() + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.loadingService.finalizeLoading();
      },
      (error) => {
        this.loadingService.finalizeLoading();
      }
    ))
  }

  showPerfil() {
    this.router.navigate(['admin/perfil'])
  }

  getTitleIR(): string {
    return "Informe de Rendimentos " + moment().subtract(1, 'year').year().toString();
  }

  getInitialFromName(): string {
    let nameAux = (this.name.trim().split(" ", 2));
    let alias = "";

    nameAux.forEach(element => {
      alias += element[0];
    });

    return alias;
  }
}
