<div class="dropdown">
  <div class="dropdown-container" [ngStyle]="{'height.px': height}" [ngClass]="{'disabled': control.disabled, 'active': active}" (click)="toogleDropdown()">
    <div class="placeholder">{{ control.value ? control.value : placeholder }}</div>

    <i *ngIf="!active && !loading" class="fas fa-chevron-down icon"></i>
    <i *ngIf="active && !loading"  class="fas fa-chevron-up icon"></i>
  </div>

 
    <ul *ngIf="active"  class="options-container">
      <li class="option" *ngFor="let option of options" (click)="selectOption(option)" [ngClass]="{'selected': option.selected}">
        <div>{{ option.label }}</div>
        <i *ngIf="option.selected"  class="fas fa-check option-icon"></i>
      </li>
    </ul>

</div>