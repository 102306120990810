import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss']
})
export class FilterCardComponent implements OnInit {

  @Input() quantity: number =  0;
  @Input() startDate: string = '';
  @Input() endDate: string =  '';
  @Input() status: string =  '';
  @Input() account: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
