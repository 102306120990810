import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: string): string {
    if (phoneNumber) {
      const value = phoneNumber.toString().replace(/\D/g, '');

      let formattedPhoneNumber = '';

      if (value.length > 12) {
        formattedPhoneNumber = value.replace(
          /(\d{2})?(\d{2})?(\d{5})?(\d{4})/,
          '+$1 ($2) $3-$4'
        );
      } else if (value.length > 11) {
        formattedPhoneNumber = value.replace(
          /(\d{2})?(\d{2})?(\d{4})?(\d{4})/,
          '+$1 ($2) $3-$4'
        );
      } else if (value.length > 10) {
        formattedPhoneNumber = value.replace(
          /(\d{2})?(\d{5})?(\d{4})/,
          '($1) $2-$3'
        );
      } else if (value.length > 9) {
        formattedPhoneNumber = value.replace(
          /(\d{2})?(\d{4})?(\d{4})/,
          '($1) $2-$3'
        );
      } else if (value.length > 5) {
        formattedPhoneNumber = value.replace(
          /^(\d{2})?(\d{4})?(\d{0,4})/,
          '($1) $2-$3'
        );
      } else if (value.length > 1) {
        formattedPhoneNumber = value.replace(/^(\d{2})?(\d{0,5})/, '($1) $2');
      }

      return formattedPhoneNumber;
    }

    return '';
  }
}
