import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConstatsRoutes } from 'src/app/utils/consts';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  constructor(private router: Router) { }

  redirect(route: ConstatsRoutes, id?: string) {
    switch (route) {
      case ConstatsRoutes.LOGIN:
        this.router.navigate([ConstatsRoutes.LOGIN]);
        break;
      case ConstatsRoutes.FORGOTPASS:
        this.router.navigate([ConstatsRoutes.FORGOTPASS]);
        break;
      case ConstatsRoutes.PAINEL:
        this.router.navigate([ConstatsRoutes.PAINEL]);
        break;
      case ConstatsRoutes.ERROR:
      default:
        this.router.navigate([ConstatsRoutes.ERROR]);
        break;
    }
  }

}
