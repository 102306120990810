import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  transform(value: string): string {
    switch (value.toLowerCase()) {
      case 'accepted':
        return 'Ativo';
      case 'created':
        return 'Pendente';
      case 'refused':
        return 'Recusado';
      default:
        return value;
    }
  }
}