import { Injectable } from "@angular/core";

const endpoints= {
    GET: [
        '/cards',
        '/collection',
        '/collection/balance',
        '/banking/limits',
        '/banking/fees',
        '/banking/balance',
        '/banking/statement',
        '/payments/info/:transactionId',
        '/payments/slip',
        '/payments/request',
        '/payments/request/file/emission/response',
        '/pix/keys',
        '/pix/keys-claims',
        '/pix/keys/:keyId/portability-claims',
        '/pix/keys/:keyId/ownership-claims',
        '/pix/keys/lookup/:keyId',
        '/pix/payment/:paymentId',
        '/pix/limits',
    ],
    PUT: [
        '/payments/request/file/emission/:method',
    ],
    DELETE: [
        '/payments/internal-payment/refund/:transactionId',
        '/payments/request/file/payment/:CNABtype/detail/:fileID',
        '/payments/request/file/emission/cancel/:key',
        '/pix/keys/:keyId',
        '/pix/keys/:keyId/portability-claims',
        '/pix/keys/:keyId/ownership-claims'
    ],
    PATCH: [
        '/banking/limits',
        '/payments/request/batch/:key',
    ],
    POST: [
        '/cards',
        '/cards/:id/reissue',
        '/scheduler/payments',
        '/collection',
        '/banking/deposit-slip',
        '/banking/billing-slip',
        '/payments/internal-payment',
        '/payments/slip',
        '/banking/bank-transfer',
        '/mobile-topup',
        '/payments/request/ted',
        '/payments/request/approval/:key',
        '/payments/request/internal-transfer',
        '/payments/request/slip',
        '/payments/request/mobile-topup',
        '/purchases',
        '/purchases/cancel',
        '/chargebacks',
        '/chargebacks/cancel',
        '/withdrawals',
        '/withdrawals/cancel',
        '/transfer',
        '/transfer/cancel',
        '/qrcode/simple-payment',
        '/pix/keys',
        '/pix/keys/:keyId/confirm',
        '/pix/keys/:keyId/portability-claims',
        '/pix/keys/:keyId/ownership-claims',
        '/pix/payment',
        '/pix/payment/:paymentId/refund',
        '/pix/qr-code/deposit',
        '/pix/qr-code/payment',
        '/pix/qr-code/static',
        '/pix/qr-code/decode',
        '/pix/limits',
        '/pix/keys/:keyId/portability-claim-responses',
        '/pix/keys/:keyId/ownership-claim-responses',
        '/payments/request/pix',
        '/payments/request/refund-pix',
        '/tenant/files/config'
    ]
}

@Injectable()
export class Endpoints {
    getEndpoints(): any{
        return endpoints;
    }
}