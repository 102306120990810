import { IClient } from "src/app/models/authentication/client";
import { IToken } from "src/app/models/authentication/token";
import { UserAccountInfo } from "src/app/models/user-account/account-data";
import { IUserManagers } from "src/app/models/user-account/user-data";
import { UserNotification, UserSettings } from "src/app/models/authentication/userSettings";

export default interface AuthState {
  client: IClient | null;
  notifications: any[];
  tokens: IToken | null
  isLogged: boolean;
  error: Error | null;
  apiKey: string | null;
  tenant: string | null;
  settings: UserSettings;
  accountType: string | null;
  clientAccounts: UserAccountInfo[];
  clientAccountSelected: UserAccountInfo | null;
  managers: IUserManagers[] | null;
}

export const initialState: AuthState = {
  client: null,
  tokens: null,
  isLogged: false,
  error: null,
  apiKey: null,
  tenant: null,
  settings: new UserSettings('', false, false, false, false, false, new UserNotification(false, false,
    false, false, false)),
  accountType: null,
  clientAccounts: [],
  notifications: [],
  clientAccountSelected: null,
  managers: null
}
