import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { getClientData, getTenant, getToken } from '../state/auth-state/auth.selectors';
import AuthState from '../state/auth-state/auth.state';
import { AuthService } from './auth.service';
import { ConfigApp } from 'src/app/utils/configApp';
import { getConfigs } from '../state/general-state/general.selectors';
import { IProfilePermissions } from 'src/app/models/profiles-accesses/profile-permissions';
import { UserAccountInfo } from 'src/app/models/user-account/account-data';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    tenant: string = '';
    configsApp: any;
    permissions: IProfilePermissions | null = null;
    selectedAccount: UserAccountInfo | null = null;
    subscription: Subscription = new Subscription();

    constructor(
        private store: Store<AuthState>,
        private authService: AuthService,
        public router: Router
    ) {
        this.store.select(getTenant).subscribe((tenant) => {
            if (tenant)
                this.tenant = tenant;
        });

        this.store.select(getClientData).subscribe(data => {

            if (data) {
                this.selectedAccount = data.selectedAccount;
                this.permissions = data.selectedAccount?.operatorConfigProfile.permission ?? null;
            }
        });

        this.subscription.add(this.store.select(getConfigs).subscribe((configs) => {
            if (configs) {
                this.configsApp = configs;
            }
        }));
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        return this.store.select(getToken).pipe(take(1))
            .pipe(map(authToken => this.authService.isAuthenticated(authToken ?? '')))
            .pipe(tap(islogged => {
                if (!islogged) {
                    this.router.navigate([`/authentication/login/${this.tenant}`]);
                }
            }))
            .pipe(map(isLogged => {
                if (isLogged) {
                    let menuName = next.routeConfig?.path;
                    if (menuName) {
                        menuName = menuName.split('/')[0];
                        const hasAccess = this.validateItemMenu(menuName);
                        if (!hasAccess) {
                            this.router.navigate(['authentication/404']);
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            }));
    }

    validateItemMenu(menuName: string): boolean {
        return ConfigApp.validateItemMenu(menuName, this.configsApp, this.permissions, this.selectedAccount?.type ?? "");
    }
}
