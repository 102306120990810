<div class="main-container" [dir]="dir"
  [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark,  'horizontal' : horizontal }">

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{'minisidebar': minisidebar}" [hasBackdrop]="actionSubmenu">

    <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
      [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
      (close)="sidebarOpened = false">

      <perfect-scrollbar class="scbar" [config]="config">
        <app-sidebar (newItemEvent)="changeSideBar($event)" [snavFull]="snav" class="app-sidebar">
        </app-sidebar>
      </perfect-scrollbar>

    </mat-sidenav>

    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''" style="overflow-x: hidden;">

      <div class="page-content" *ngIf="!mobileQuery.matches" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-icon-button (click)="snav.toggle()" class="sidebar-toggle" value="sidebarclosed">
          <mat-icon>menu</mat-icon>
        </button>

        <img [src]="logo" alt="logo">
        <div (click)="snav2.toggle()" class="user-profile-menu">
          <img *ngIf="image" [src]="image">
          <div *ngIf="!image" class="user-profile-no-photo">
            {{getInitialFromName()}}
          </div>
        </div>

        <mat-sidenav #snav2 id="snav2" class="pl-xs" fixedTopGap="0" [opened]="mobileQuery.matches"
          [disableClose]="mobileQuery.matches" position="end" (open)="sidebarOpened = true"
          (close)="sidebarOpened = false">

          <perfect-scrollbar class="scbar menu-perfil" [config]="config">
            <div class="company-logo" fxLayoutAlign="center center">
              <mat-icon class="close-menu" (click)="snav2.close()">close</mat-icon>
            </div>

            <button mat-menu-item (click)="showPerfil()">
              <i class="fas fa-id-badge m-r-10"></i> Ver perfil
            </button>

            <button mat-menu-item (click)="downloadIR()">
              <i class="fas fa-file-alt m-r-10"></i>{{getTitleIR()}}
            </button>
            
            <button mat-menu-item (click)="logout()" class="exit">
              <i class="fas fa-sign-out-alt m-r-10"></i> Sair
            </button>

          </perfect-scrollbar>
        </mat-sidenav>

      </div>

      <!-- <app-breadcrumb></app-breadcrumb> -->

      <router-outlet >
        <div class="loading-container" style="padding-top: 30px;" >
          <app-loading uid="loading-general"></app-loading>
          <app-spinner></app-spinner>
        </div>
      </router-outlet>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>

<div class="modal-session" *ngIf="hasTimeoutSession">
  <div class="content-modal-session">
    <h1 class="atention">ATENÇÃO</h1>
    <div>Sua sessão irá terminar por inatividade em: <span class="time-session"> {{ formatText(count) }}</span></div>
    <div class="btn btn-session" (click)="continueSession()">Continuar</div>
  </div>
</div>