import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() placeholder: string = '';
  @Input() control: FormControl = new FormControl();

  @Output() emitter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  keyUp(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.emitter.emit(filterValue);
  }

}
