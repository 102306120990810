import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, exhaustMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import AuthState from '../state/auth-state/auth.state';
import { AuthService } from '../auth/auth.service';
import { getAccountSelected, getApiKey, getTenant, getToken } from '../state/auth-state/auth.selectors';
import { getAccountType } from './../state/auth-state/auth.selectors';
import { Endpoints } from 'src/app/shared/endpoints/endpoints';
import { getApiUrl } from 'src/app/core/state/general-state/general.selectors';
import { environment } from 'src/environments/environment';
import { Account } from 'src/app/models/authentication/account';
import { getSelectedAccount } from 'src/app/admin/transfer/state/transfer.selectors';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {

  tenant: string = '';
  apiKey: string = '';
  token: string = '';
  url: string = '';
  accountIndex: string = '';
  endpointsToCheck: Object = {};

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<AuthState>,
    private endpoints: Endpoints,
  ) {
    this.endpointsToCheck = this.endpoints.getEndpoints();
    this.store.select(getTenant).subscribe((tenant) => {
      if (tenant)
        this.tenant = tenant;
    })

    this.store.select(getApiKey).subscribe((apiKey) => {
      if (apiKey)
        this.apiKey = apiKey;
    })

    this.store.select(getToken).subscribe((token) => {
      if (token) {
        this.token = token;
      }
    })

    this.store.select(getAccountSelected).subscribe((account) => {
      if (account) {
        this.accountIndex = account?.accountIndex ?? '';
      }
    })

    this.store.select(getApiUrl).subscribe((url) => {
      this.url = url;
    })

  }

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<any>> {
    const key = this.authService.key;

    if (this.checkEndpoint(request)) {
      if (request.method == "GET") {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'x-api-key': this.apiKey,
            observe: 'response'
          },
          setParams: {
            accountIndex: this.accountIndex,
          }
        });
      } else {   
         request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'x-api-key': this.apiKey,
          },
          body:Object.assign(request.body!, {accountIndex: this.accountIndex})
        });
      }
    } else {
      if(request.url.includes("diletta")){
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'x-api-key': this.apiKey,
            observe: 'response'
          }
        });
      }else{
        request = request
      }
      
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.router.navigate(['authentication/login/' + this.tenant]);
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  checkEndpoint(request: HttpRequest<any>): boolean {
    let endpointsToCheck = this.endpoints.getEndpoints()
    let url= this.cleanUrl(request.url)
    return endpointsToCheck[request.method].some((el: string) => el == url);
  }

  cleanUrl(url:string):string{
    let clearUrl = url.replace(this.url, '');
    if(url.includes(environment.apiUrl)){
      clearUrl=url.replace(environment.apiUrl,'')
    }
    clearUrl = clearUrl.replace(/(\/v[0-9])\/d[a-zA-Z]+/, '');
    return clearUrl
  }

}