export interface IUserSettings {
  contaEscrava: string;
  custom: boolean;
  notification_autorization: boolean;
  reset_password_required: boolean;
  reset_pin_required: boolean;
  use_biometrics: boolean;
  user_notifications: UserNotification
}

export class UserNotification {
  new: boolean;
  on_purchase: boolean;
  on_withdraw: boolean;
  send_over_email: boolean;
  send_over_sms: boolean;

  constructor(newItem: boolean, on_purchase: boolean, on_withdraw: boolean, send_over_email: boolean, send_over_sms: boolean) {
    this.new = newItem;
    this.on_purchase = on_purchase;
    this.on_withdraw = on_withdraw;
    this.send_over_email = send_over_email;
    this.send_over_sms = send_over_sms
  }
}

export class UserSettings implements IUserSettings {
  contaEscrava: string;
  custom: boolean;
  notification_autorization: boolean;
  reset_password_required: boolean;
  reset_pin_required: boolean;
  use_biometrics: boolean;
  user_notifications: UserNotification;

  constructor(contaEscrava: string, custom: boolean, notification_autorization: boolean, reset_password_required: boolean,
    reset_pin_required: boolean, use_biometrics: boolean, user_notifications: UserNotification) {
    this.contaEscrava = contaEscrava;
    this.custom = custom;
    this.notification_autorization = notification_autorization;
    this.reset_password_required = reset_password_required;
    this.reset_pin_required = reset_pin_required;
    this.use_biometrics = use_biometrics;
    this.user_notifications = user_notifications;
  }
}