import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { Menu, MenuItems } from '../../../shared/menu-items/menu-items';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { setEventSubmenu } from 'src/app/core/state/general-state/general.actions';
import { Router } from '@angular/router';
import { setAccountIndex, setClientAccountSelected } from 'src/app/core/state/auth-state/auth.actions';
import { LoginService } from 'src/app/services/login/login.service';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { environment } from 'src/environments/environment';
import { getTenant, getClientData } from 'src/app/core/state/auth-state/auth.selectors';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { AccountTypeService } from './../../../services/account-type/account-type.service';
import { AccountsTypes, ConstatsRoutes } from 'src/app/utils/consts';
import { MixpanelService } from 'src/app/services/mix-panel/mix-panel.service';
import * as moment from 'moment';
import { UserAccountInfo } from 'src/app/models/user-account/account-data';
import { ConfigApp } from 'src/app/utils/configApp';
import { getConfigs } from 'src/app/core/state/general-state/general.selectors';
import { IProfilePermissions } from 'src/app/models/profiles-accesses/profile-permissions';
import { IUserManagers } from 'src/app/models/user-account/user-data';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { MatDialog } from '@angular/material/dialog';
import { AboutDialogComponent } from 'src/app/dialogs/about-dialog/about-dialog.component';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: []
})
export class AppSidebarComponent implements OnInit, OnDestroy {
    @Output() newItemEvent = new EventEmitter<boolean>();
    @Input() snavFull: any;

    miniSideBar = false;
    image: any;
    name: string = "User Name";

    public config: PerfectScrollbarConfigInterface = {};
    mobileQuery: MediaQueryList;

    sysSettings: any;
    private _mobileQueryListener: () => void;
    status = true;

    itemSelect: number[] = [];
    parentIndex = 0;
    childIndex = 0;
    permissions: IProfilePermissions | null = null;
    configsApp: any;
    subscription: Subscription = new Subscription();
    logo: any;
    tenant: string = '';
    icon: any;
    showSubnavBody: boolean = true;
    accounts: Array<UserAccountInfo> = [];
    selectedAccount: UserAccountInfo | null = null;
    open: boolean = false;
    isEscrow: boolean = false;

    menu: Array<Menu> = [];

    managerIndexView = 0;
    managers: IUserManagers[] = [];

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public menuItems: MenuItems,
        private store: Store,
        private router: Router,
        private loginService: LoginService,
        private settingsService: SettingsService,
        private loadingService: LoadingService,
        private AccountTypeService: AccountTypeService,
        private mixpanelService: MixpanelService,
        private tenantService: TenantService,
        private dialog: MatDialog,

    ) {
        this.mobileQuery = media.matchMedia('(min-width: 960px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);

        this.store.select(getClientData).subscribe(data => {

            if (data) {
                this.menu = this.menuItems.getMenuitem(AccountsTypes.DEFAULT);
                const namesSplited = data.client?.name.split(" ") ?? [];
                this.name = data.client ? `${namesSplited[0]} ${namesSplited[namesSplited.length - 1]}` : ""
                this.accounts = data.accounts;
                this.selectedAccount = data.selectedAccount;
                this.permissions = data.selectedAccount?.operatorConfigProfile.permission ?? null;
            }
        });

        this.subscription.add(this.store.select(getTenant).subscribe((tenant) => {
            if (tenant)
                this.tenant = tenant;
        }));

        this.subscription.add(this.store.select(getConfigs).subscribe((configs) => {
            if (configs) {
                this.configsApp = configs;
            }
        }));

        this.loginService.getClientProfile().subscribe((x) => {
            let reader = new FileReader();
            if (x.type == 'image/png' || x.type == 'image/jpeg') {
                reader.addEventListener("load", () => {
                    this.image = reader.result;
                })
                if (x) {
                    reader.readAsDataURL(x);
                }
            }
        });
    }

    ngOnInit(): void {
    }

    setClickedRow(i: number, j: number) {
        this.parentIndex = i;
        this.childIndex = j;
    }

    subClickEvent() {
        this.status = true;
    }

    scrollToTop() {
        document.querySelector('.page-wrapper')?.scroll({
            top: 0,
            left: 0
        });
    }

    getClientLogo(): void {
        this.subscription.add(this.settingsService.getLogo().subscribe(
            (result) => {
                let reader = new FileReader();
                reader.addEventListener("load", () => {
                    this.logo = reader.result;
                })
                if (result) {
                    reader.readAsDataURL(result);
                }
            },

        ))
    }

    getIconTenant() {
        for (const tenant of environment.tenants) {
            if (tenant.name === this.tenant) {
                this.icon = tenant.icon;
            }
        }
    }

    validateItemMenu(menuName: string): boolean {
        return ConfigApp.validateItemMenu(menuName, this.configsApp, this.permissions, this.selectedAccount?.type ?? "");
    }

    validateSubItemMenu(menuName: string): boolean {
        return ConfigApp.validateSubItemMenu(menuName, this.configsApp, this.permissions, this.selectedAccount?.type ?? '');
    }

    menuAction() {
        this.miniSideBar = !this.miniSideBar;
        this.newItemEvent.emit(this.miniSideBar);
    }

    getIcon(icon: IconProp) {
        return icon;
    }

    logout() {
        this.loginService.logout();
    }

    downloadIR() {
        this.loadingService.initializeLoading();
        this.subscription.add(this.settingsService.getIncomeReports(moment().subtract(1, 'year').year().toString()).subscribe(
            (result) => {
                const linkSource = 'data:application/pdf;base64,' + result.contentFile;
                const downloadLink = document.createElement("a");
                const fileName = "informe-rendimentos-" + moment().subtract(1, 'year') + ".pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                this.loadingService.finalizeLoading();
            },
            (error) => {
                this.loadingService.finalizeLoading();
            }
        ))
    }

    onOpenedSubmenu() {
        this.store.dispatch(setEventSubmenu({ eventName: 'opened' }));
    }

    onClosedSubmenu() {
        this.store.dispatch(setEventSubmenu({ eventName: 'closed' }));
    }

    formatRouterLink(param1: string, param2: string, param3: string) {
        return '/' + param1 + '/' + param2 + '/' + param3;
    }

    setSelectedSubmenu(partialUrl: string) {
        if (this.router.url == partialUrl) {
            return 'selected'
        } else {
            return '';
        }
    }

    closeMenu() {
        this.snavFull.toggle();
    }

    closeAccounts() {
        this.open = false
    }

    closeHeaderSubnav(event: any, childItem: any) {
        event.stopPropagation();
        this.showSubnavBody = !this.showSubnavBody;
        childItem.active = !childItem.active;
    }

    showPerfil() {
        this.router.navigate(['admin/perfil'])
    }

    async getAboutData() {
        const result = await this.settingsService.getAboutTXTData().toPromise();
        const lines = result.split('\n');
        const parsedData: Record<string, string> = {};
    
        lines.forEach(line => {
            const [key, value] = line.split('=');
            parsedData[key] = value;
        });
    
        return parsedData;
    }
    

    async showAbout(): Promise<void> {
        const txtData = await this.getAboutData();

        this.dialog.open(AboutDialogComponent, {
            data: {
                version: txtData?.VERSION,
                release: txtData?.DATE_RELEASE,
            }
        });
      }

    getInitialFromName(): string {
        let nameAux = (this.name.trim().split(" ", 2));
        let alias = "";

        nameAux.forEach(element => {
            alias += element[0];
        });

        return alias;
    }

    getAccountIndex(account: any) {
        switch (account) {
            case 'Conta Dependente':
                return 'DEPENDENT';
            case 'Conta Escrow':
                return 'ESCROW';
            default:
                return 'MAIN'
        }
    }

    chosenAccount(index: number) {
        this.router.navigate([ConstatsRoutes.PAINEL]);
        this.open = false;
        this.selectedAccount = this.accounts[index];
        this.store.dispatch(setClientAccountSelected({ account: this.selectedAccount! }));
        this.store.dispatch(setAccountIndex({ account: this.selectedAccount!.accountIndex }));
    }

    getMenu(type: AccountsTypes): void {
        this.menu = this.menuItems.getMenuitem(type);
    }

    openPopUp() {
        this.open = !this.open
    }

    track(menuItem: string): void {
        if (menuItem.includes("Cobrança Avançada")) {
            this.mixpanelService.track("IB_Latest_" + menuItem);
        }
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.subscription.unsubscribe();
    }

    getTitleIR(): string {
        return "Informe de Rendimentos " + moment().subtract(1, 'year').year().toString();
    }

    mailTo(email: string) { }

    toPascalCase(str: string) {
        return str
          .split(/[\s_-]/) 
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' '); 
      }

    getTypeAndNameAccount(taxId: string = '', name: string = ''){
        const typeAccount = taxId?.length === 11 ? 'PF' : 'PJ';
        const accountName = name?.length > 18 ? name.slice(0, 18).concat('...') : name;

        return `${typeAccount} - ${this.toPascalCase(accountName) ?? ''}`;
    }
}
