import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TenantService } from 'src/app/services/tenant/tenant.service';

export interface IAboutDialogComponent {
  version: string;
  release: string;
  active: boolean;

}
@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutDialogComponent implements OnInit {

  version: string = '';
  release: string = '';
  active: boolean = false;
  subscription: Subscription = new Subscription();
  logoSvg: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAboutDialogComponent,
    public dialogRef: MatDialogRef<IAboutDialogComponent>,
    private tenantService: TenantService,
  ) { 
  }

  ngOnInit(): void {
    this.getClientLogo()
    this.version = this.data.version;
    this.release = this.data.release;
    this.active = this.data.active;
    
  }

  getClientLogo(): void {
    this.logoSvg = this.tenantService.getLogoSvg();
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
