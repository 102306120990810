import { createAction, props } from "@ngrx/store";
import { Account } from "src/app/models/authentication/account";
import { Token } from "src/app/models/authentication/token";
import { UserAccountInfo } from "src/app/models/user-account/account-data";
import { UserProfile } from "src/app/models/user-account/user-data";

export enum AuthActionType {
  LOGIN = '[AUTH] Login start',
  LOGIN_SUCCESS = '[AUTH] Login success',
  LOGIN_FAIL = '[AUTH] Login fail',
  GET_CLIENT = '[AUTH] Get client',
  GET_CLIENT_SUCCESS = '[AUTH] Get client success',
  GET_CLIENT_FAIL = '[AUTH] Get client Fail',
  SET_ACCOUNT_TYPE = '[AUTH] Get account type',
  LOGOUT = '[AUTH] Logout user',
  SET_API_kEY = '[AUTH] Set api key',
  SET_TOKENS = '[AUTH] Set tokens',
  SET_CLIENT = '[AUTH] Set client',
  SET_TENANT = '[AUTH] Set tenant',
  SET_SETTINGS = '[AUTH] Set settings',
  SET_CLIENT_ACCOUNTS = '[AUTH] Set client accounts',
  SET_CLIENT_ACCOUNT_SELECTED = '[AUTH] Set client account selected'
}

export const loginStart = createAction(
  AuthActionType.LOGIN,
  props<{ account: Account }>()
)

export const setTenant = createAction(
  AuthActionType.SET_TENANT,
  props<{ tenant: string }>()
);


export const loginSuccess = createAction(
  AuthActionType.LOGIN_SUCCESS,
  props<{ tokens: Token }>()
)

export const setTokens = createAction(
  AuthActionType.SET_TOKENS,
  props<{ tokens: Token }>()
)

export const setClient = createAction(
  AuthActionType.SET_CLIENT,
  props<{ client: UserProfile }>()
)

export const setClientAccounts = createAction(
  AuthActionType.SET_CLIENT_ACCOUNTS,
  props<{ accounts: UserAccountInfo[] }>()
)

export const setClientAccountSelected = createAction(
  AuthActionType.SET_CLIENT_ACCOUNT_SELECTED,
  props<{ account: UserAccountInfo }>()
)

export const setSettings = createAction(
  AuthActionType.SET_SETTINGS,
  props<{ settings: any }>()
)

export const setApiKey = createAction(
  AuthActionType.SET_API_kEY,
  props<{ apiKey: string }>()
)

export const getClientFail = createAction(
  AuthActionType.GET_CLIENT_FAIL,
  props<{ error: Error | any }>()
)

export const setAccountIndex = createAction(
  AuthActionType.SET_ACCOUNT_TYPE,
  props<{ account: string }>()
)

export const logoutSession = createAction(
  AuthActionType.LOGOUT
)