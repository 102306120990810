import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppRoutes } from './app.routing';
import { NgxViacepModule } from "@brunoc/ngx-viacep";

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';

import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material-module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBarcode, faChartLine, faChartPie, faCheckSquare, faCreditCard, faCube, faDonate, faExchangeAlt, faFile, faFileArchive, faFileCsv, faFileInvoice, faHandsHelping, faHome, faList, faMobileAlt, faPiggyBank, faQrcode, faReceipt, faSquare, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';

import { InovantiBankCoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { LoadingService } from './shared/loading/loading.service';

import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthGuard } from './core/auth/auth-guard';
import { UserIdleModule } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    SpinnerComponent,
    AppBlankComponent,
    AppSidebarComponent,
    AppBreadcrumbComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    MaterialModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InovantiBankCoreModule,
    FontAwesomeModule,
    NgxViacepModule,
    UserIdleModule.forRoot({ idle: 1140, timeout: 30, ping: 5000 })
  ],
  providers: [
    LoadingService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AuthGuard,
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    CookieService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    this.setPalletColor();

    library.addIcons(
      faReceipt, faBarcode, faHome, faSquare, faCheckSquare, faChartPie, faWallet, faExchangeAlt, faBarcode, faCreditCard, faMobileAlt, faDonate, faPiggyBank, faQrcode, faHandsHelping, faCube, faChartLine, faList, faUser, faFile, faFileArchive, faFileInvoice);
  }

  private setPalletColor() {
    document.documentElement.style.setProperty(`--primary`, '#F0F2F5');
    document.documentElement.style.setProperty(`--primary50`, '#3949B2');
    document.documentElement.style.setProperty(`--primary100`, '#3F69D2');
    document.documentElement.style.setProperty(`--primary200`, '#427AE5');
    document.documentElement.style.setProperty(`--primary300`, '#458EF9');
    document.documentElement.style.setProperty(`--primary400`, '#469CFF');
    document.documentElement.style.setProperty(`--primary500`, '#71BAFF');
    document.documentElement.style.setProperty(`--primary600`, '#C0E0FF');

    document.documentElement.style.setProperty(`--neutral`, '#FFFFFF');
    document.documentElement.style.setProperty(`--neutral50`, '#F7F7F7');
    document.documentElement.style.setProperty(`--neutral75`, '#DEDEDE');
    document.documentElement.style.setProperty(`--neutral100`, '#CCCCCC');
    document.documentElement.style.setProperty(`--neutral200`, '#A3A3A3');
    document.documentElement.style.setProperty(`--neutral300`, '#7A7A7A');
    document.documentElement.style.setProperty(`--neutral400`, '#525252');
    document.documentElement.style.setProperty(`--neutral500`, '#292929');
    document.documentElement.style.setProperty(`--neutral550`, '#1B1B1B');
    document.documentElement.style.setProperty(`--neutral600`, '#000000');

    document.documentElement.style.setProperty(`--success50`, '#DCFEEA');
    document.documentElement.style.setProperty(`--success300`, '#0CBA52');

    document.documentElement.style.setProperty(`--error50`, '#FFEAEA');
    document.documentElement.style.setProperty(`--error300`, '#EC0505');

    document.documentElement.style.setProperty(`--alert50`, '#FFF7DB');
    document.documentElement.style.setProperty(`--alert300`, '#FBC400');

    document.documentElement.style.setProperty(`--information50`, '#0561EC');
    document.documentElement.style.setProperty(`--information100`, '#EAF2FF');

    document.documentElement.style.setProperty(`--donuts-positive-1`, '#065D29');
    document.documentElement.style.setProperty(`--donuts-positive-2`, '#05EC61');
    document.documentElement.style.setProperty(`--donuts-positive-3`, '#86DDA9');
    document.documentElement.style.setProperty(`--donuts-negative-1`, '#4F0202');
    document.documentElement.style.setProperty(`--donuts-negative-2`, '#9D0303');
    document.documentElement.style.setProperty(`--donuts-negative-3`, '#EC0505');
    document.documentElement.style.setProperty(`--donuts-negative-4`, '#F14444');
    document.documentElement.style.setProperty(`--donuts-negative-5`, '#F68282');
    document.documentElement.style.setProperty(`--donuts-negative-6`, '#FAC1C1');

    document.documentElement.style.setProperty(`--miscellaneous-red`, '#EC0505');
    document.documentElement.style.setProperty(`--miscellaneous-pink`, '#F8017C');
    document.documentElement.style.setProperty(`--miscellaneous-grape`, '#D306D3');
    document.documentElement.style.setProperty(`--miscellaneous-biolet`, '#6F00DD');
    document.documentElement.style.setProperty(`--miscellaneous-indigo`, '#1818E9');
    document.documentElement.style.setProperty(`--miscellaneous-blue`, '#0574E2');
    document.documentElement.style.setProperty(`--miscellaneous-cyan`, '#01D1D1');
    document.documentElement.style.setProperty(`--miscellaneous-teal`, '#00AA55');
    document.documentElement.style.setProperty(`--miscellaneous-green`, '#00D000');
    document.documentElement.style.setProperty(`--miscellaneous-lime`, '#73E500');
    document.documentElement.style.setProperty(`--miscellaneous-yellow`, '#FBFB06');
    document.documentElement.style.setProperty(`--miscellaneous-orange`, '#F67B00');

    document.documentElement.style.setProperty(`--backgroundcolor`, '#F3F0EA');
  }
}
