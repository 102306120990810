export enum CompetenceTransactionType {
  PIX = "PIX",
  TED_SAME_USER = "TED_SAME_USER",
  TED_OTHER_USER = "TED_OTHER_USER",
  INTERNAL_TRANSFER = "INTERNAL_TRANSFER",
  SLIP_PAYMENT = "SLIP_PAYMENT",
  APPLY_INVESTMENT = "APPLY_INVESTMENT",
  WITHDRAW_INVESTMENT = "WITHDRAW_INVESTMENT",
  ESCROW_TRANSFER = "ESCROW_TRANSFER"
}

export enum CompetenceParseType {
  PIX = "Aprovação de PIX",
  TED_SAME_USER = "Aprovação de transferências mesma titularidade",
  TED_OTHER_USER = "Aprovação de transferências outras titularidades",
  INTERNAL_TRANSFER = "Aprovação de transferências mesma titularidade",
  SLIP_PAYMENT = "Transferência da Conta Vinculada",
  APPLY_INVESTMENT = "Aplicar em Investimentos",
  WITHDRAW_INVESTMENT = "Resgatar em Investimentos",
  ESCROW_TRANSFER = "Transferência da Conta Escrow"
}

export enum OperatorInviteStatus {
  CREATED = "CREATED", // convidado
  CANCELED = "CANCELED", // cancelado
  ACCEPTED = "ACCEPTED", // ativo
  BLOCKED = "BLOCKED", // bloqueado
}

export enum StatusEnum {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  DISABLE = 'disable',
  CREATED = 'created'
}