import { Injectable } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { getConfigs } from 'src/app/core/state/general-state/general.selectors';
import { AccountsTypes } from 'src/app/utils/consts';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
    active?: boolean;
}
export interface ChildItem {
    state: string;
    name: string;
    useAssets?: boolean,
    assetPath?: string,
    type?: string;
    subchildren?: SubChildren[];
    icon?: string;
    active?: boolean;
    padding?: boolean;
    goldColor?: boolean;
}

export interface Menu {
    state: string;
    module: string;
    name: string;
    type: string;
    noAction?: boolean;
    subtype?: string;
    icon: IconProp;
    useAssets: boolean,
    assetPath?: string,
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildItem[];
}

@Injectable()
export class MenuItems {
    usePixAdvance: boolean = false;
    
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.select(getConfigs).subscribe((configs) => {
          this.usePixAdvance = configs.pix.advanced_pix_qrcode_support;
        })
    }

    getMenuitem(type: AccountsTypes): Menu[] {
        return [
                {
        state: 'painel',
        module: 'admin',
        name: 'Painel',
        type: 'link',
        icon: ["fas", "home"],
        useAssets: false
    }, 
    {
        state: 'conta-corrente',
        module: 'admin',
        name: 'Extrato',
        type: 'sub',
        icon: ["fas", "file-invoice"],
        useAssets: false,
        children: [
            { state: 'extrato', name: 'Extrato', type: 'link', icon: 'receipt', padding: false },
            { state: 'agendamentos', name: 'Agendamentos', type: 'link', icon: 'receipt', padding: true },
            { state: 'lotes-de-boletos-processados', name: 'Lotes Processados', type: 'link', icon: 'receipt', padding: false },
            // { state: 'lotes-de-boletos-emitidos', name: 'Lotes de Boletos Emitidos', type: 'link', icon: 'receipt', padding: false },
            // { state: 'lotes-de-teds-processados', name: 'Lotes de Transferências Processadas', type: 'link', icon: 'receipt', padding: false }
        ]
    },
    {
        state: 'pagamentos',
        module: 'admin',
        name: 'Pagamentos',
        type: 'link',
        icon: ["fas", "barcode"],
        useAssets: false
    },
    {
        state: 'pagar',
        module: 'admin',
        name: 'Pagamentos',
        type: 'sub',
        icon: ["fas", "barcode"],
        useAssets: false,
        children: [
            { state: 'pagar-boleto', name: 'Boleto de Cobrança', type: 'link' },
            { state: 'pagar-boleto-lote', name: 'Pagamento via Arquivos', type: 'link' }
        ]
    },
    {
        state: 'transferencias',
        module: 'admin',
        name: 'Transferências',
        type: 'link',
        icon: ["fas", "barcode"],
        useAssets: false
    },
    {
        state: 'transferencia',
        module: 'admin',
        name: 'Transferências',
        type: 'sub',
        icon: ["fas", "exchange-alt"],
        useAssets: false,
        children: [
            { state: 'enviar-dinheiro-interna-ted', name: 'Transferência Interna ou TED', type: 'link' },
            { state: 'ted-lotes', name: 'Realizar Transferências em Lote', type: 'link' }
        ]
    },
    {
        state: 'pix',
        module: 'admin',
        name: 'Pix',
        type: 'sub',
        icon: ["fas", "square"],
        assetPath: 'assets/icons/icon-pix-solid.svg',
        useAssets: true,
        children: [
            { state: 'via-chave-pix', name: 'Transferência Via Chave Pix', type: 'link' },
            { state: 'via-dados-conta', name: 'Transferência Via Agência e Conta', type: 'link' },
            { state: 'pix-copia-cola', name: 'Pix Copia e Cola', type: 'link', padding: true },
            { state: 'chaves-pix', name: 'Minhas Chaves Pix', type: 'link', padding: true },
            { state: 'cobrancas', name: 'Cobrança via Pix', type: 'link' },
            { state: 'cobrancas-emitidas', name: 'Cobranças Pix', type: 'link' }
        ]
    },
    {
        state: 'aprovacoes',
        module: 'admin',
        name: 'Aprovações',
        type: 'sub',
        icon: ["fas", "list"],
        useAssets: false,
        children: [
            { state: 'pendentes', name: 'Aprovações Pendentes', type: 'link' },
            { state: 'historico', name: 'Histórico de Aprovações', type: 'link', padding: true }
        ]
    },
    
    {
        state: 'cobrar',
        module: 'admin',
        name: 'Cobrar',
        type: 'sub',
        subtype: 'subtitle',
        icon: ["fas", "donate"],
        useAssets: false,
        children: [
          {
            state: 'cobrar-boleto', name: 'Boleto', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
              { state: 'emitir-boleto', name: 'Emitir Boleto', type: 'link' }
            ]
          },
          {
            state: 'cobrar-lote', name: 'Lote', type: 'subtitle', icon: 'barcode', active: true, subchildren: [
              { state: 'emitir-lote-boleto', name: 'Emitir Lote de Boletos', type: 'link' }
            ]
          },
          {
            state: 'cobrar-pix', name: 'Pix', type: 'subtitle', icon: 'donate', active: true,
            subchildren: !this.usePixAdvance ? [  { state: 'cobranca-simples', name: 'Cobrança Simples', type: 'link' }] : [
              { state: 'cobranca-simples', name: 'Cobrança Simples', type: 'link' },
              { state: 'cobranca-avancada', name: 'Cobrança Avançada', type: 'link'},
            ]
          },
          {
            state: 'cobranca-enviada', name: 'Cobranças Enviadas', type: 'subtitle', icon: 'mail-bulk' , active: true, subchildren: [
                { state: 'cobranca-enviada', name: 'Cobranças Enviadas', type: 'link', },
            ]
          },
          
        ]
      },
    // {
    //     state: 'cobranca',
    //     module: 'admin',
    //     name: 'Cobranças',
    //     type: 'sub',
    //     icon: ["fas", "donate"],
    //     useAssets: false,
    //     children: [
    //         { state: 'saldo-vinculada', name: 'Saldo/Extrato da Conta Vinculada', type: 'link' },
    //         { state: 'solicitar-transferencia', name: 'Solicitar Transferência da Conta Vinculada', type: 'link' },
    //         { state: 'incluir-novas-garantias', name: 'Incluir Novas Garantias na Conta Vinculada', type: 'link', padding: true },
    //         { state: 'consulta-bordero', name: 'Consultar Borderôs', type: 'link' },
    //         { state: 'consulta-titulos', name: 'Consultar Títulos', type: 'link' },
    //         { state: 'consulta-sacados', name: 'Sacados', type: 'link' },
    //         { state: 'acompanhamento-cobrancas', name: 'Acompanhamentos de Cobranças', type: 'link' },
    //         { state: 'arquivo-retorno', name: 'Arquivos de Retorno', type: 'link' }
    //     ]
    // },
    // {
    //     state: 'emprestimo',
    //     module: 'admin',
    //     name: 'Empréstimo',
    //     type: 'sub',
    //     icon: ["fas", "donate"],
    //     useAssets: false,
    //     children: [
    //         { state: 'nova-cessao', name: 'Incluir títulos para Cessão', type: 'link', padding: true },
    //     ]
    // },
    {
        state: 'servicos',
        module: 'admin',
        name: 'Configurações',
        type: 'sub',
        icon: ['fas',"cog"],
        useAssets: false,
        children: [
            { state: 'gestao-acessos', name: 'Usuários', type: 'link' },
            { state: 'gestao-perfis', name: 'Perfis', type: 'link' },
            { state: 'alcadas-servico', name: 'Alçadas', type: 'link'},
            { state: 'cesta-tarifaria', name: 'Cesta Tarifária', type: 'link'},
            { state: 'limites', name: 'Limites', type: 'link' }
        ]
    },
    // {
    //     state: 'conta-pool',
    //     module: 'admin',
    //     name: 'Conta Pool',
    //     type: 'sub',
    //     icon: ["fas", "donate"],
    //     useAssets: false,
    //     children: [
    //         { state: 'cadastro-conta-pool', name: 'Cadastro', type: 'link' },
    //         { state: 'listagem-usuario-pool', name: 'Listagem de Usuários', type: 'link' },
    //     ]
    // },
    // {
    //     state: 'dashboard-pool',
    //     module: 'admin',
    //     name: 'Dashboard Pool',
    //     type: 'link',
    //     icon: ["fas", "users"],
    //     useAssets: false
    // },
        ]
    }
}
