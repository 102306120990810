
import { createAction, props } from "@ngrx/store";
import { Statement } from "src/app/models/statement/statement";



export enum BankingActionsTypes {
  SET_COLLECTIONS = '[BANK] Get collections success',
  SET_STATEMENT = '[BANK] Get statements success',
  SET_STATEMENT_EMPTY = '[BANK] Set statement empty',
  SET_CHART_DONUT_TYPE = '[CHARTS] Set chart donut type',
  SET_COLLECTIONS_EMPTY = '[CHARTS] Set collections empty',
  SHOW_BALANCE = '[BALANCE] Show balance'
}

export const setCollections = createAction(
  BankingActionsTypes.SET_COLLECTIONS,
  props<{ collections: any }>()
)

export const setStatements = createAction(
  BankingActionsTypes.SET_STATEMENT,
  props<{ statements: Statement[] }>()
)

export const setStatementEmpty = createAction(
  BankingActionsTypes.SET_STATEMENT_EMPTY
);

export const setCollectionsEmpty = createAction(
  BankingActionsTypes.SET_COLLECTIONS_EMPTY
);

export const setChartDonutType = createAction(
  BankingActionsTypes.SET_CHART_DONUT_TYPE,
  props<{ typeDonut: string }>()
)
export const showBalance = createAction(
  BankingActionsTypes.SHOW_BALANCE,
  props<{ show: boolean }>()
)