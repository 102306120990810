enum PartialRoutesName {
    AUTHENTICATION = 'authentication',
    ADMIN = 'admin',
}


export enum EnumAllRoutesName {
    ERROR = '404',
    FORGOTPASS = 'forgot',
    LOCKSCREEN = 'lockscreen',
    LOGIN = 'login/:tenant',
    LOGINNOTENANT = 'login',
    REGISTER = 'register',

    RESET_PIN = 'reset/pin',
    CHANGE_PIN = 'change/pin',

    RESET_PASS = 'reset/pass',
    CHANGE_PASS = 'change/pass',

    PAINEL = 'painel',
    INVESTIMENT = 'investimentos',

    GESTAOACESSOS = 'servicos/gestao-acessos',
    ACESSOSNEWUSER = 'servicos/novo-usuario',
    ACESSOSEDITUSER = 'servicos/editar-usuario/:id',
    GESTAOPERFIS = 'servicos/gestao-perfis',
    GESTAOPERFISNOVO = 'servicos/gestao-perfis/novo',
    GESTAOPERFISDETALHES = 'servicos/gestao-perfis/detalhes/:id',
    ALCADASSERVICO = 'servicos/alcadas-servico',
    TARIFFBASKETS = 'servicos/cestas-tarifarias',
    ONETARIFFBASKET = 'servicos/cesta-tarifaria',
    LIMITS = 'servicos/limites',

    SERVICOASSINATURA = 'servicos/assinaturas',
    SERVICOINFORMERENDIMENTOS = 'servicos/informe-rendimentos',
    SERVICODISPOSITIVOS = 'servicos/dispositivos',
    SERVICOCONSULTAPACOTES = 'servicos/consulta-pacotes',
    SERVICOEXTRATOANUAL = 'servicos/extrato-anual',
    SERVICOSOBRE = 'servicos/sobre',
    SERVICODUVIDAS = 'servicos/duvidas',


    CCEXTRATO = 'conta-corrente/extrato',
    CCSALDO = 'conta-corrente/saldo',
    CCCOMPROVANTES = 'conta-corrente/comprovantes',
    CCAGENDAMENTOS = 'conta-corrente/agendamentos',
    CCTRANSFERENCIASDEVOLVIDAS = 'conta-corrente/transferencia-devolvidas',
    CCAUTORIZACOES = 'conta-corrente/autorizacoes',
    CCCOBRANCAENVIADAS = 'conta-corrente/cobranca-enviada',
    CCCARTAOCREDITO = 'conta-corrente/cartao-credito',
    CCLOTESDEBOLETOSPROCESSADOS = 'conta-corrente/lotes-de-boletos-processados',
    CCLOTESDETEDPROCESSADOS = 'conta-corrente/lotes-de-teds-processados',
    CCLOTESDEBOLETOSEMITIDOS = 'conta-corrente/lotes-de-boletos-emitidos',
    CCCESTATARIFARIA = 'conta-corrente/cesta-tarifaria',
    CCCLIMITES= 'conta-corrente/limites',

    PIXLERQRCODE = 'pix/ler-qrcode',
    PIXCOPIACOLA = 'pix/pix-copia-cola',
    PIXAGENDAMENTOS = 'pix/agendamentos',
    PIXCOMPROVANTES = 'pix/comprovantes',
    PIXAPROVACOES = 'pix/aprovacoes',
    PIXCONSULTALIMITES = 'pix/consulta-limites',
    PIXDEVOLVIDOS = 'pix/pix-devolvido',
    PIXTRANSFVIACHAVEPIX = 'pix/via-chave-pix',
    PIXTRANSENVIAPIX = 'pix/enviar-dinheiro-pix',
    PIXTRANSFVIADADOSCONTA = 'pix/via-dados-conta',
    PIXCOBRANCASIMPLES = 'pix/cobrancas',
    PIXCOBRANCAEMITIDAS = 'pix/cobrancas-emitidas',
    PIXCOBRANCAAVANCADA = 'pix/cobranca-avancada',
    PIXCHAVESPIX = 'pix/chaves-pix',
    PIXLIMITEPIX = 'pix/limite-pix',
    PIXLIGARCENTRAL = 'pix/ligar-para-central',
    PIXACESSOSUPORTE = 'pix/acesso-ao-suporte',


    TRANSFCOLOCARPIX = 'transferencia/colocar-dinheiro-pix',
    TRANSFENCIAS = 'transferencias',
    TRANSFCOLOCARBOLETO = 'transferencia/colocar-dinheiro-boleto',
    TRANSFRECEBERPIXCOBRANCASIMPLES = 'transferencia/receber-dinheiro-pix-cobranca-simples',
    TRANSFRECEBERPIXCOBRANCAAVANCADA = 'transferencia/receber-dinheiro-pix-cobranca-avancada',
    TRANSFRECEBEREMITIRBOLETO = 'transferencia/receber-dinheiro-emitir-boleto',
    TRANSFENVIARPIX = 'transferencia/enviar-dinheiro-pix',
    TRANSFENCADASTROFAVORECIDOS = 'transferencia/cadastro-favorecidos',
    TRANSFENDEVOLVIDAS = 'transferencia/transferencias-devolvidas',
    TRANSFENAGENDAMENTOS = 'transferencia/agendamentos',
    TRANSFENCOMPROVANTES = 'transferencia/comprovantes',
    TRANSFENAPROVACOES = 'transferencia/aprovacoes',
    TRANSFENCONSULTAS = 'transferencia/consulta-limites',
    TRANSFERENCIAPORCONDAVIAPIX = 'transferencia/via-dados-conta',
    TRANSFENVIARINTERNOTED = 'transferencia/enviar-dinheiro-interna-ted',
    TRANSFENVIARCONTAASA = 'transferencia/enviar-dinheiro-interna-ted',
    TRANSFENVIARPAGARBOLETO = 'transferencia/enviar-dinheiro-pagar-boleto',
    TRANSFLOTETED = 'transferencia/ted-lotes',
    TRANSFLOTETEDPROCESSADOS = 'transferencia/lotes-de-teds-processados',

    PAGARBOLETO = 'pagar/pagar-boleto',
    PAGAMENTOSBOLETO = 'pagamentos',
    PAGARCONCESSIONARIAS = 'pagar/pagar-concessionarias',
    PAGARTRIBUTOS = 'pagar/pagar-tributos',
    PAGARDDA = 'pagar/pagar-dda',
    PAGARAGENDAMENTO = 'pagar/agendamento',
    PAGARCADASTRODDA = 'pagar/cadastro-dda',
    PAGARCOMPROVANTES = 'pagar/comprovantes',
    PAGARAPROVACOES = 'pagar/aprovacoes',
    PAGARLIMITES = 'pagar/consulta-limites',
    PAGARVIACHAVEPIX = 'pagar/via-chave-pix',
    PAGARPIXCOPIAECOLA = 'pagar/pix-copia-cola',
    PAGARLOTEBOLETO = 'pagar/pagar-boleto-lote',
    PAGARLOTESDEBOLETOSPROCESSADOS = 'pagar/lotes-de-boletos-processados',

    APROVACOESCOMPROVANTES = 'aprovacoes/comprovantes',
    APROVACOESCONSULTALIMITES = 'aprovacoes/consulta-limites',
    APROVACOESPENDENTES = 'aprovacoes/pendentes',
    APROVACOESHISTORICO = 'aprovacoes/historico',

    INVESTIMENTOSPOSICAOCARTEIRA = 'investimentos/posicao-carteira',
    INVESTIMENTOSFUNDOINVESTIMENTOS = 'investimentos/fundo-investimento',
    INVESTIMENTOSAPLICARINVESTIMENTOS = 'investimentos/aplicar-investimento',
    INVESTIMENTOSRESGATARINVESTIMENTOS = 'investimentos/resgatar-investimento',
    INVESTIMENTOSPERFILINVESTIDOR = 'investimentos/perfil-investidor',


    DEPOSITAREMITIRBOLETO = 'depositar/dinheiro-boleto',
    DEPOSITARPIXCOBRANCASIMPLES = 'depositar/cobranca-simples',

    COBRAREMITIRBOLETO = 'cobrar/emitir-boleto',
    COBRARPIXCOBRANCASIMPLES = 'cobrar/cobranca-simples',
    COBRARPIXCOBRANCAAVANCADA = 'cobrar/cobranca-avancada',
    COBRARLOTEBOLETO = 'cobrar/emitir-lote-boleto',
    COBRARCOBRANCAENVIADAS = 'cobrar/cobranca-enviada',

    ESCROWEMITIRBOLETO = 'conta-escrow/emitir-boleto',
    ESCROWECOBRANCAAVANCADA = 'conta-escrow/cobranca-avancada',
    ESCROWCHAVESPIX = 'conta-escrow/chaves-pix',
    ESCROWCONTA = 'conta-escrow/conta-escrow',
    ESCROWPAINEL = 'conta-escrow/painel',
    ESCROWCOBRANCASENVIADAS = 'conta-escrow/cobrancas-enviadas',

    LOTESPAGARBOLETO = 'lotes/pagar-boleto-lote',
    LOTESEMITIRBOLETO = 'lotes/emitir-lote-boleto',
    LOTESBOLETOSPROCESSADOS = 'lotes/lotes-de-boletos-processados',
    LOTESBOLETOSEMITIDOS = 'lotes/lotes-de-boletos-emitidos',
    LOTESTRANSFERIR = 'lotes/ted-lotes',
    LOTESREALIZARTED = 'lotes/realizar-ted-lote',
    LOTESTEDPROCESSADOS = 'lotes/lotes-de-teds-processados',

    RECARGATELEFONE = 'recarga-telefone',
    PERFIL = 'perfil',
    CIANO = 'ciano',
    HOME = 'home',

    EMPRESTIMOSVINCULADASALDOEXTRATO = 'cobranca/saldo-vinculada',
    EMPRESTIMOSVINCULADASOLICITARTRANSFERENCIA = 'cobranca/solicitar-transferencia',
    EMPRESTIMOSVINCULADAINCLUIRNOVASGARANTIAS = 'cobranca/incluir-novas-garantias',
    COBRANCAVINCULADAINCLUIRNOVASCESSAO = 'cobranca/nova-cessao',
    EMPRESTIMOSVINCULADAINCLUIRNOVASCESSAO = 'emprestimo/nova-cessao',
    EMPRESTIMOSCESSAOINCLUIRTITULOS = 'cobranca/incluir-titulos',
    EMPRESTIMOSCONSULTASBORDEROS = 'cobranca/consulta-bordero',
    EMPRESTIMOSDETALHESBORDEROS = 'cobranca/detalhe-bordero/:bordero/:oficial',
    EMPRESTIMOSCONSULTASTTITULOS = 'cobranca/consulta-titulos',
    EMPRESTIMOSCONSULTASLISTASACADOS = 'cobranca/consulta-sacados',
    EMPRESTIMOSCONSULTASNOVOSACADOS = 'cobranca/novo-sacado',
    EMPRESTIMOSCONSULTASEDITASACADOS = 'cobranca/edita-sacado/:id',
    EMPRESTIMOSCONSULTASACOMPANHAMENTOCOBRANCA = 'cobranca/acompanhamento-cobrancas',
    EMPRESTIMOSCONSULTASARQUIVORETORNO = 'cobranca/arquivo-retorno',

    CADASTROCONTAPOOL = 'conta-pool/cadastro-conta-pool',
    LISTAGEMUSUARIOPOOL = 'conta-pool/listagem-usuario-pool',
    DETALHEUSUARIOPOOL = 'conta-pool/detalhe-usuario-pool',
    
    DASHBOARDPOOL = 'dashboard-pool'
}

export class ConstatsRoutes {
    static ERROR = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.ERROR}`;
    static FORGOTPASS = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.FORGOTPASS}`;
    static LOCKSCREEN = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.LOCKSCREEN}`;
    static LOGIN = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.LOGIN}`;
    static LOGINNOTENANT = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.LOGINNOTENANT}`;
    static REGISTER = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.REGISTER}`;

    static RESET_PIN = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.RESET_PIN}`;
    static CHANGE_PIN = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.CHANGE_PIN}`;

    static RESET_PASS = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.RESET_PASS}`;
    static CHANGE_PASS = `/${PartialRoutesName.AUTHENTICATION}/${EnumAllRoutesName.CHANGE_PASS}`;

    static PAINEL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAINEL}`;
    static INVESTIMENT = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.INVESTIMENT}`;

    static GESTAOACESSO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.GESTAOACESSOS}`;
    static ACESSOSNEWUSER = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ACESSOSNEWUSER}`;
    static ACESSOSEDITUSER = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ACESSOSEDITUSER}`;
    static GESTAOPERFIL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.GESTAOPERFIS}`;
    static GESTAOPERFILNOVO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.GESTAOPERFISNOVO}`;
    static GESTAOPERFISDETALHES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.GESTAOPERFISDETALHES}`;
    static ALCADASSERVICO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ALCADASSERVICO}`;
    static TARIFFBASKETS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TARIFFBASKETS}`;
    static ONETARIFFBASKET = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ONETARIFFBASKET}`;
    static LIMITS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LIMITS}`;


    static CCEXTRATO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCEXTRATO}`;
    static CCSALDO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCSALDO}`;
    static CCCOMPROVANTES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCCOMPROVANTES}`;
    static CCAGENDAMENTOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCAGENDAMENTOS}`;
    static CCTRANSFERENCIASDEVOLVIDAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCTRANSFERENCIASDEVOLVIDAS}`;
    static COBRARCOBRANCAENVIADAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRARCOBRANCAENVIADAS}`;
    static CCCARTAOCREDITO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCCARTAOCREDITO}`;
    static CCAUTORIZACOES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCAUTORIZACOES}`;
    static CCLOTESDEBOLETOSPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCLOTESDEBOLETOSPROCESSADOS}`
    static CCLOTESDETEDPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCLOTESDETEDPROCESSADOS}`;
    static CCLOTESDEBOLETOSEMITIDOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCLOTESDEBOLETOSEMITIDOS}`;
    static CCCESTATARIFARIA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCCESTATARIFARIA}`;
    static CCLIMITES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CCCLIMITES}`;
    static APROVACOESPENDENTES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.APROVACOESPENDENTES}`;


    static PIXLERQRCODE = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXLERQRCODE}`;
    static PIXCOPIACOLA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXCOPIACOLA}`;
    static PIXTRANSFVIACHAVEPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXTRANSFVIACHAVEPIX}`;
    static PIXTRANSENVIAPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXTRANSENVIAPIX}`;
    static PIXTRANSFVIADADOSCONTA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXTRANSFVIADADOSCONTA}`;
    static PIXCOBRANCASIMPLES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXCOBRANCASIMPLES}`;
    static PIXCOBRANCAEMITIDAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXCOBRANCAEMITIDAS}`;
    static PIXCOBRANCAAVANCADA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXCOBRANCAAVANCADA}`;
    static PIXCHAVESPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXCHAVESPIX}`;
    static PIXLIMITEPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXLIMITEPIX}`;
    static PIXLIGARCENTRAL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXLIGARCENTRAL}`;
    static PIXACESSOSUPORTE = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PIXACESSOSUPORTE}`;

    static LOTESPAGARBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESPAGARBOLETO}`;
    static LOTESEMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESEMITIRBOLETO}`;
    static LOTESBOLETOSPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESBOLETOSPROCESSADOS}`;
    static LOTESBOLETOSEMITIDOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESBOLETOSEMITIDOS}`;
    static LOTESTRANSFERIR = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESTRANSFERIR}`;
    static LOTESTEDPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESTEDPROCESSADOS}`;
    static LOTESREALIZARTED = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LOTESREALIZARTED}`;

    static TRANSFCOLOCARPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFCOLOCARPIX}`;
    static TRANSFENCIAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENCIAS}`;
    static TRANSFCOLOCARBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFCOLOCARBOLETO}`;
    static TRANSFRECEBERPIXCOBRANCASIMPLES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFRECEBERPIXCOBRANCASIMPLES}`;
    static TRANSFRECEBERPIXCOBRANCAAVANCADA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFRECEBERPIXCOBRANCAAVANCADA}`;
    static TRANSFRECEBEREMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFRECEBEREMITIRBOLETO}`;
    static TRANSFENVIARPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENVIARPIX}`;
    static TRANSFENDEVOLVIDAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENDEVOLVIDAS}`;
    static TRANSFENCADASTROFAVORECIDOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENCADASTROFAVORECIDOS}`;
    static TRANSFERENCIAPORCONDAVIAPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFERENCIAPORCONDAVIAPIX}`;
    static TRANSFENVIARINTERNOTED = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENVIARINTERNOTED}`;
    static TRANSFENVIARCONTAASA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENVIARCONTAASA}`;
    static TRANSFENVIARPAGARBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFENVIARPAGARBOLETO}`;
    static TRANSFLOTETED = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFLOTETED}`;
    static TRANSFLOTETEDPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.TRANSFLOTETEDPROCESSADOS}`;

    static PAGARBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARBOLETO}`;
    static PAGAMENTOSBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGAMENTOSBOLETO}`;
    static PAGARCONCESSIONARIAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARCONCESSIONARIAS}`;
    static PAGARTRIBUTOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARTRIBUTOS}`;
    static PAGARDDA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARDDA}`;
    static PAGARAGENDAMENTO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARAGENDAMENTO}`;
    static PAGARCADASTRODDA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARCADASTRODDA}`;
    static PAGARCOMPROVANTES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARCOMPROVANTES}`;
    static PAGARAPROVACOES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARAPROVACOES}`;
    static PAGARLIMITES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARLIMITES}`;
    static PAGARVIACHAVEPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARVIACHAVEPIX}`;
    static PAGARPIXCOPIAECOLA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARPIXCOPIAECOLA}`;
    static PAGARLOTEBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARLOTEBOLETO}`;
    static PAGARLOTESDEBOLETOSPROCESSADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PAGARLOTESDEBOLETOSPROCESSADOS}`

    static DEPOSITAREMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.DEPOSITAREMITIRBOLETO}`;
    static DEPOSITARPIXCOBRANCASIMPLES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.DEPOSITARPIXCOBRANCASIMPLES}`;

    static COBRAREMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRAREMITIRBOLETO}`;
    static COBRARPIXCOBRANCASIMPLES = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRARPIXCOBRANCASIMPLES}`;
    static COBRARPIXCOBRANCAAVANCADA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRARPIXCOBRANCAAVANCADA}`;
    static COBRARLOTEBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRARLOTEBOLETO}`;

    static ESCROWEMITIRBOLETO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWEMITIRBOLETO}`;
    static ESCROWECOBRANCAAVANCADA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWECOBRANCAAVANCADA}`;
    static ESCROWCHAVESPIX = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWCHAVESPIX}`;
    static ESCROWCONTA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWCONTA}`;
    static ESCROWPAINEL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWPAINEL}`;
    static ESCROWCOBRANCASENVIADAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.ESCROWCOBRANCASENVIADAS}`;

    static RECARGATELEFONE = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.RECARGATELEFONE}`;
    static PERFIL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.PERFIL}`;
    static CIANO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CIANO}`;

    static EMPRESTIMOSVINCULADASALDOEXTRATO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSVINCULADASALDOEXTRATO}`;
    static EMPRESTIMOSVINCULADASOLICITARTRANSFERENCIA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSVINCULADASOLICITARTRANSFERENCIA}`;
    static EMPRESTIMOSVINCULADAINCLUIRNOVASGARANTIAS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSVINCULADAINCLUIRNOVASGARANTIAS}`;
    static EMPRESTIMOSVINCULADAINCLUIRNOVASCESSAO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSVINCULADAINCLUIRNOVASCESSAO}`;
    static COBRANCAVINCULADAINCLUIRNOVASCESSAO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.COBRANCAVINCULADAINCLUIRNOVASCESSAO}`;
    static EMPRESTIMOSCESSAOINCLUIRTITULOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSCESSAOINCLUIRTITULOS}`;
    static EMPRESTIMOSCONSULTASBORDEROS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSCONSULTASBORDEROS}`;
    static EMPRESTIMOSDETALHESBORDEROS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSDETALHESBORDEROS}`;
    static EMPRESTIMOSCONSULTASTTITULOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSCONSULTASTTITULOS}`;
    static EMPRESTIMOSCONSULTASLISTASACADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSCONSULTASLISTASACADOS}`;
    static EMPRESTIMOSCONSULTASNOVOSACADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSCONSULTASNOVOSACADOS}`;
    static EMPRESTIMOSCONSULTASEDITASACADOS = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSCONSULTASEDITASACADOS}`;
    static EMPRESTIMOSCONSULTASACOMPANHAMENTOCOBRANCA = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSCONSULTASACOMPANHAMENTOCOBRANCA}`;
    static EMPRESTIMOSCONSULTASARQUIVORETORNO = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.EMPRESTIMOSCONSULTASARQUIVORETORNO}`;

    static CADASTROCONTAPOOL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.CADASTROCONTAPOOL}`;
    static LISTAGEMUSUARIOPOOL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.LISTAGEMUSUARIOPOOL}`;
    static DETALHEUSUARIOPOOL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.DETALHEUSUARIOPOOL}`;
    static DASHBOARDPOOL = `/${PartialRoutesName.ADMIN}/${EnumAllRoutesName.DASHBOARDPOOL}`;

    static getLastRouteName(routeName: string) {
        return routeName.substring(routeName.lastIndexOf('/') + 1);
    }
}

export enum EnumMps {
    MP = 'Daqui à 3 dias úteis',
    MP5 = 'Daqui à 5 dias úteis',
    MP10 = 'Daqui à 10 dias úteis',
    MP15 = 'Daqui à 15 dias úteis',
    MP30 = 'Daqui à 30 dias úteis',
    MP60 = 'Daqui à 60 dias úteis',
    MP90 = 'Daqui à 90 dias úteis'
}

export const constArrayMp = [
    { text: "Á vista", value: "VISTA" },
    { text: "Daqui à 1 dias útil", value: "MP1" },
    { text: "Daqui à 2 dias úteis", value: "MP2" },
    { text: "Daqui à 3 dias úteis", value: "MP" },
    { text: "Daqui à 4 dias úteis", value: "MP4" },
    { text: "Daqui à 5 dias úteis", value: "MP5" },
    { text: "Daqui à 6 dias úteis", value: "MP6" },
    { text: "Daqui à 7 dias úteis", value: "MP7" },
    { text: "Daqui à 8 dias úteis", value: "MP8" },
    { text: "Daqui à 9 dias úteis", value: "MP9" },
    { text: "Daqui à 10 dias úteis", value: "MP10" },
    { text: "Daqui à 11 dias úteis", value: "MP11" },
    { text: "Daqui à 12 dias úteis", value: "MP12" },
    { text: "Daqui à 13 dias úteis", value: "MP13" },
    { text: "Daqui à 14 dias úteis", value: "MP14" },
    { text: "Daqui à 15 dias úteis", value: "MP15" },
    { text: "Daqui à 16 dias úteis", value: "MP16" },
    { text: "Daqui à 17 dias úteis", value: "MP17" },
    { text: "Daqui à 18 dias úteis", value: "MP18" },
    { text: "Daqui à 19 dias úteis", value: "MP19" },
    { text: "Daqui à 20 dias úteis", value: "MP20" },
    { text: "Daqui à 21 dias úteis", value: "MP21" },
    { text: "Daqui à 22 dias úteis", value: "MP22" },
    { text: "Daqui à 23 dias úteis", value: "MP23" },
    { text: "Daqui à 24 dias úteis", value: "MP24" },
    { text: "Daqui à 25 dias úteis", value: "MP25" },
    { text: "Daqui à 26 dias úteis", value: "MP26" },
    { text: "Daqui à 27 dias úteis", value: "MP27" },
    { text: "Daqui à 28 dias úteis", value: "MP28" },
    { text: "Daqui à 29 dias úteis", value: "MP29" },
    { text: "Daqui à 30 dias úteis", value: "MP30" },
    { text: "Daqui à 31 dias úteis", value: "MP31" },
    { text: "Daqui à 32 dias úteis", value: "MP32" },
    { text: "Daqui à 33 dias úteis", value: "MP33" },
    { text: "Daqui à 34 dias úteis", value: "MP34" },
    { text: "Daqui à 35 dias úteis", value: "MP35" },
    { text: "Daqui à 60 dias úteis", value: "MP60" },
    { text: "Daqui à 90 dias úteis", value: "MP90" },
];

export const constInterestsModality = [
    { code: 0, description: "Selecione a modalidade" },
    { code: 1, description: "Valor (dias corridos)" },
    { code: 2, description: "Percentual ao dia (dias corridos)" },
    { code: 3, description: "Percentual ao mês (dias corridos)" },
    { code: 4, description: "Percentual ao ano (dias corridos)" },
    { code: 5, description: "Valor (dias úteis)" },
    { code: 6, description: "Percentual ao dia (dias úteis)" },
    { code: 7, description: "Percentual ao mês (dias úteis)" },
    { code: 8, description: "Percentual ao ano (dias úteis)" }
];

export const constFinesModality = [
    { code: 0, description: "Selecione a modalidade" },
    { code: 1, description: "Valor fixo" },
    { code: 2, description: "Percentual" },
];

// unique discount
export const constDiscountModality = [
    { code: 0, description: "Selecione a modalidade" },
    { code: 1, description: "Valor Fixo até a[s] data[s] informada[s]" },
    { code: 2, description: "Percentual até a data informada" },
    { code: 3, description: "Valor por antecipação dia corrido" },
    { code: 4, description: "Valor por antecipação dia útil" },
    { code: 5, description: "Percentual por antecipação dia corrido" },
    { code: 6, description: "Percentual por antecipação dia útil" },
];

export const constRebateModality = [
    { code: 0, description: "Selecione a modalidade" },
    { code: 1, description: "Valor Fixo" },
    // { code: 2, description: "Percentual" }
];

export enum EnumTransactionTypesChargeSended {
    InstantPayment = "InstantPayment",
    BoletoCobranca = "BoletoCobranca"
}

export enum EnumPaymentStatus {
    Registado = "registrado",
    Pago = "pago",
    RemovidoPeloUsuario = "removido_usuario"
}


export const approverQuantity = [
    { label: 'Único', value: 1 },
    { label: '2 aprovadores em conjunto', value: 2 },
    { label: '3 aprovadores em conjunto', value: 3 },
]

export enum AccountsTypes {
    DEFAULT = 'default',
}

export const DATE_FORMAT = 'yyyy-MM-DD';

export const fineTypesList = [
    { name: 'Fine-value', id: 'VALUE' },
    { name: 'Fine-percentage', id: 'PERCENTAGE' },
    { name: 'Fine-no-fine', id: 'EXEMPT' },
  ];

export const feesTypesList = [
    { name: 'Fee-value-daily', id: 'VALUE_DAILY' },
    { name: 'Fee-percentage-daily', id: 'PERCENTAGE_DAILY' },
    { name: 'Fee-percentage-monthly', id: 'PERCENTAGE_MONTHLY' },
    { name: 'Fee-percentage-yearly', id: 'PERCENTAGE_YEARLY' },
    { name: 'Fee-exempt', id: 'EXEMPT' },
    { name: 'Fee-value-business-daily', id: 'VALUE_BUSINESS_DAILY' },
    { name: 'Fee-percentage-business-daily', id: 'PERCENTAGE_BUSINESS_DAILY' },
    { name: 'Fee-percentage-business-monthly', id: 'PERCENTAGE_BUSINESS_MONTHLY' },
    { name: 'Fee-percentage-business-yearly', id: 'PERCENTAGE_BUSINESS_YEARLY' },
  ];

export const discountTypesList = [
    { name: 'Discount-no-discount', id: 'EXEMPT' },
    { name: 'Discount-fixed', id: 'FIXED' },
    { name: 'Discount-percentage', id: 'PERCENTAGE' },
    { name: 'Discount-fixed-calendar', id: 'FIXED_CALENDAR' },
    { name: 'Discount-fixed-business', id: 'FIXED_BUSINESS' },
    { name: 'Discount-percentage-calendar', id: 'PERCENTAGE_CALENDAR' },
    { name: 'Discount-percentage-business', id: 'PERCENTAGE_BUSINESS' },
  ]

  export const fineTypesSlipCreationList = [
    { name: 'Fine-value', id: 'VALUE' },
    { name: 'Fine-percentage', id: 'PERCENTAGE' },
    { name: 'Fine-no-fine', id: 'EXEMPT' },
  ];        

export const feesTypesSlipCreationList = [
    { name: 'Fee-percentage-monthly', id: 'PERCENTAGE_MONTHLY' },
    { name: 'Fee-exempt', id: 'EXEMPT' },
  ];

export const discountTypesSlipCreationList = [
    { name: 'Discount-no-discount', id: 'EXEMPT' },
    { name: 'Discount-fixed', id: 'FIXED' },
    { name: 'Discount-percentage', id: 'PERCENTAGE' },
  ]