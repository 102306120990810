import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface DropdownOption {
  label: string;
  value: any;
  selected?: boolean
}


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  active: boolean = false;
  values: string[] = [];
  label: string = '';

  @Input() multiple: boolean = false;
  @Input() placeholder!: string;
  @Input() control: FormControl = new FormControl('');
  @Input() options: DropdownOption[] = []
  @Input() loading: boolean = false;
  @Input() height: number = 56;

  @Output() emitter = new EventEmitter<any>();

  ngOnInit(): void {
    if (this.control.value !== '') {
      this.values = this.control.value.trim().split(',');
      this.values = this.values.map(value => value.trim())
      this.options.forEach((option: DropdownOption) => {
        if (this.values.includes(option.label)) {
          option.selected = true;
        }
      })
      this.setValue();
    }
  }

  toogleDropdown(): void {
    if (!this.control.disabled) {
      this.active = !this.active;
    }
  }

  selectOption(option: DropdownOption): void {
    this.options.forEach(o => o.selected = false);
    this.values = [option.label]
    option.selected = true;
    this.setValue();
    this.active = false;
    this.emitter.emit(option.value)
  }

  setValue(): void {
    this.label = this.values.join(', ')
    this.control.setValue(this.label);
  }

}
