export const environment = {
  envName: 'octa',
  production: false,
  firebase: {
    apiKey: "AIzaSyCTuyacv8LMloqqWMbC63f-axYbckKfeBU",
    authDomain: "octa-prd.firebaseapp.com",
    databaseURL: "https://octa-prd-default-rtdb.firebaseio.com",
    projectId: "octa-prd",
    storageBucket: "octa-prd.firebasestorage.app",
    messagingSenderId: "1009118995992",
    appId: "1:1009118995992:web:5e634406bd40f77f34456d"
  },
  
  tenants: [
    {
      tenant: "octa-prd-ex4ha",
      name: "octa",
      primaryColor: "#458EF9",
      overPrimary: "#0dd3ff",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTczMTQ1ODA3NjYyOSwiYXVkIjoiYXNhcC1wcmQiLCJzdWIiOiJvY3RhIiwianRpIjoiNWIwMWEwMjAtYzBkNS00YjU3LWExMTQtOWM1MTJjYWU3ZTUwIiwidGVuYW50SWQiOiJvY3RhLXByZC1leDRoYSIsImF1dGhUZW5hbnRJZCI6Im9jdGEtcHJkLXVzZXJzLWNhODBhIn0.cQZVNMzTnxEAE-XWxz8RWUpnyRQx5SUrMD_3VCLmsSfxiC2w7PzFoJSNkcBkBZdssP-M2ZORdo0-2Q7AgG2kjw",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://asap-prd.uc.r.appspot.com',
      formattedName: "Octa SCD",
      icon: "https://storage.googleapis.com/asap-prd.appspot.com/tenant-assets/Octa/icon",
      logo: "https://storage.googleapis.com/asap-prd.appspot.com/tenant-assets/Octa/logo",
      orderSupport: false,
      useLocalConfig: false,
      useLocalColors: true,
    }
  ],
  apiUrl: 'https://asap-prd.uc.r.appspot.com',
  keyEncryptAuth: 'oGTy7QtyHr48GE6re5KTckkGnbm6oo7SwRHsi2gqjapqflY9hjTAJ4GyUtt9YrSK',
  account: ' ',
  branch: ' ',
  wallet: ' ',
  bank: ' ',
  currency: ' '
}