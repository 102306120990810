import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/core/auth/auth.service';
import { RoutesService } from 'src/app/core/routes/routes.service';
import { setApiKey } from 'src/app/core/state/auth-state/auth.actions';
import { getTenant } from 'src/app/core/state/auth-state/auth.selectors';
import { setApiUrl, setAppId, setAppName, setConfigs, setLoginKey, setPrimaryColor } from 'src/app/core/state/general-state/general.actions';
import { BatchInfoModel } from 'src/app/models/batch-info/batch-info.module';
import { ConfigApp } from 'src/app/utils/configApp';
import { ConstatsRoutes } from 'src/app/utils/consts';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../settings/settings.service';

@Injectable({
    providedIn: 'root'
})
export class TenantService {

    configs: any;
    logo: any;
    tenant: any;
    favIcon: any = document.querySelector('#appIcon')!;
    appTitle: any = document.querySelector('#appTitle');
    account: string = '';
    branch: string = '';
    wallet: string = '';
    bank: string = '';
    apiUrl: string = '';
    apiKey: string = '';
    formattedName: string = '';

    constructor(
        private settingsService: SettingsService,
        private store: Store,
        private authService: AuthService,
        private routes: RoutesService
    ) {
        this.tenant = localStorage.getItem('tenant');

        if (!this.tenant) {
            this.store.select(getTenant).subscribe(tenant => {
                if (tenant)
                    this.tenant = tenant;
            })
        }
    }

    get getApiKey(): string {
        for (const tenant of environment.tenants) {
            if (tenant.name === this.tenant) {
                this.apiKey = tenant.apiKey;
            }
        }
        return this.apiKey;
    }

    get getApiUrl(): string {
        for (const tenant of environment.tenants) {
            if (tenant.name === this.tenant) {
                this.apiUrl = tenant.apiUrl;
            }
        }
        return this.apiUrl;
    }

    getBatchInfo(): BatchInfoModel {
        return {
            account: environment.account,
            branch: environment.branch,
            wallet: environment.wallet,
            bank: environment.bank,
            currency: environment.currency,
        }
    }

    setColorsPaletteConfig(color_palette: any): void {
        ConfigApp.configStyle(color_palette);
    }

    getClientConfig(ccf: boolean): void {
        for (const tenant of environment.tenants) {
            if (tenant.name === this.tenant) {
                this.store.dispatch(setApiKey({ apiKey: tenant.apiKey }));
                this.favIcon.href = tenant.icon!;
                this.store.dispatch(setApiUrl({ apiUrl: tenant.apiUrl }));
            }
        }

        this.initializeConfig(ccf ?? false);
    }

    getFormattedName() {
        for (const tenant of environment.tenants) {
            if (tenant.name === this.tenant) {
                this.formattedName = tenant.formattedName;
            }
        }
        return this.formattedName;
    }

    getLogo() {
        for (const tenant of environment.tenants) {
            if (tenant.name === this.tenant) {
                this.logo = tenant.logo;
            }
        }
        return this.logo;
    }

    initializeConfig(ccf: boolean): void {
        this.settingsService.initConfigTenantFile(ccf).subscribe(
            (result) => {
                this.configs = result;
                this.store.dispatch(setLoginKey({ loginKey: result.general.login.key }));
                this.store.dispatch(setAppName({ appName: result.api.app_name }));
                this.store.dispatch(setAppId({ appId: this.configs.api.app_id }));
                this.appTitle.innerText = result.api.app_name;
                this.store.dispatch(setConfigs({ configs: this.configs.general }));
                this.setColorsPaletteConfig(result.color_palette)
            },
            (error) => {
                // TO DO: VALIDAR DEPOIS RETORNO DA API
                // this.routes.redirect(ConstatsRoutes.ERROR);
            }
        )
    }
}
