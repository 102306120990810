import { AuthReducer } from "./auth-state/auth.reducers";
import { AUTH_STATE } from "./auth-state/auth.selectors";
import AuthState from "./auth-state/auth.state";
import { GeneralReducer } from "./general-state/general.reducers";
import { GENERAL_STATE_NAME } from "./general-state/general.selectors";
import { GeneralState } from "./general-state/general.state";

export interface CoreState {
  [AUTH_STATE]: AuthState,
  [GENERAL_STATE_NAME]: GeneralState
}

export const coreReducer = {
  [AUTH_STATE]: AuthReducer,
  [GENERAL_STATE_NAME]: GeneralReducer
}