import { Component, Input, OnInit } from '@angular/core';
import { StatusEnum } from 'src/app/utils/enums';

export type StatusCardType = 'active' | 'blocked' | 'disable';

@Component({
  selector: 'app-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss']
})
export class StatusCardComponent implements OnInit {

  @Input() status: StatusCardType = 'active'
  @Input() title: string = ''

  constructor() { }

  ngOnInit(): void {
  }

  getClass() {
    switch (this.status.toLowerCase()) {
      case 'ativo':
      case 'liquidado':
      case 'baixado':
      case 'accepted':
      case 'oficial':
        return StatusEnum.ACTIVE;
      case 'em análise':
      case 'bloqueado':
      case 'rejeitado':
      case 'refused':
        return StatusEnum.BLOCKED;
      case 'cancelado':
      case 'desativado':
      case 'a vencer':
        return StatusEnum.DISABLE;
      case 'created':
      case 'simulado':
      case 'vencido':
        return StatusEnum.CREATED;
      default:
        return StatusEnum.ACTIVE;
    }
  }

}
