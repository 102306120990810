import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxIdMask'
})
export class TaxIdMaskPipe implements PipeTransform {

  transform(value?: string, ...args: any[]): unknown {
    let taxId = "";
    if (value) {
      if (value.length == 11) {
        taxId = value.replace(/([*\d]{3})([*\d]{3})([*\d]{3})([*\d]{2})/, "$1.$2.$3-$4");
      } else {
        taxId = value.replace(/^([*\d]{2})([*\d]{3})([*\d]{3})([*\d]{4})([*\d]{2})/, "$1.$2.$3/$4-$5");
      }
    }

    return taxId;
  }

}
