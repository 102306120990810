import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'octa';

    constructor(private translate: TranslateService) {
        this.translate.setDefaultLang('pt-BR');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|pt-BR/) ? browserLang : 'pt-BR');
      }
}
