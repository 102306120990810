<div class="about-dialog">
  <div style="display: flex; flex-direction: column; align-items: center; gap: 6px; margin-bottom: 20px;">
    <img src="../../../assets/images/login/iso-logo.svg" alt="logo" style="width: 160px;">
  </div>

  <div class="about-dialog-column">
    <div class="body2">{{"Version" | translate}}</div>
    <div class="subtitle2">{{version}}</div>
  </div>

  <div class="about-dialog-column">
    <div class="body2">{{"Relase-date" | translate}}</div>
    <div class="subtitle2">{{release | date:'dd/MM/yyyy h:mm'}}</div>
  </div>

  <div class="about-dialog-options">
    <div class="button-basic" (click)="close()">
      <span>{{"Close" | translate}}</span>
    </div>
  </div>
</div>