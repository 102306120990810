import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-slide-toogle',
  templateUrl: './slide-toogle.component.html',
  styleUrls: ['./slide-toogle.component.scss']
})
export class SlideToogleComponent implements OnInit, OnChanges {

  @Input() control: FormControl = new FormControl(false)

  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {}

  toogle(): void {
    if (!this.control.disabled) {
      this.control.setValue(!this.control.value);
    }
  }

}
