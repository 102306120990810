import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

export interface IAboutDialogComponent {
  version: string;
  release: string;
  active: boolean;

}
@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutDialogComponent implements OnInit {

  version: string = '';
  release: string = '';
  active: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAboutDialogComponent,
    public dialogRef: MatDialogRef<IAboutDialogComponent>,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.version = this.data.version;
    this.release = this.data.release;
    this.active = this.data.active;
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
