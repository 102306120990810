<div class="filter">
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center" class="filter-button"
    (click)="filterOptionsToogle()">
    <i class="fas fa-filter" style="color: #9C8555;"></i>
    <div *ngIf="onlyPeriod">{{ startDate  | date: "dd MMM yyyy" | titlecase}} - {{ endDate  | date: "dd MMM yyyy" | titlecase}}</div>
    <div *ngIf="!onlyPeriod">Filtrar</div>
    <i *ngIf="!showFilterOptions" class="fas fa-caret-down"></i>
    <i *ngIf="showFilterOptions" class="fas fa-caret-up"></i>
  </div>

  <div *ngIf="showFilterOptions" class="filter-container" fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row">
      <div class="p-16" *ngIf="situation">
        <div class="subtitle2 m-b-24">Situação</div>
        <ul class="filter-list">
          <li *ngFor="let option of statusList; let i = index" class="m-b-12">
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="status-option">
              <mat-checkbox name="typeList" (change)="updateCheckboxStatus($event, i, option.type)"
                [checked]="option.checked">
                <i [class]="option.icon" [style.color]="option.color" style="padding-left: 5px; padding-right: 5px;">
                </i>
                {{option.name}}
              </mat-checkbox>
            </div>
          </li>

        </ul>
      </div>

      <div *ngIf="period && situation" class="divider"></div>

      <div class="p-16" *ngIf="account">
        <div class="subtitle2 m-b-24">Conta</div>
        <ul class="filter-list">
          <li *ngFor="let option of accountList; let i = index" class="m-b-12">
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="status-option">
              <mat-checkbox name="typeList" (change)="updateCheckboxStatus($event, i, option.type)"
                [checked]="option.checked">
                <i [class]="option.icon" [style.color]="option.color" style="padding-left: 5px; padding-right: 5px;">
                </i>
                {{option.name}}
              </mat-checkbox>
            </div>
          </li>
        </ul>
      </div>

      <div *ngIf="account && accountList.length > 0" class="divider"></div>


      <div class="p-16" *ngIf="period">
        <div class="subtitle2 m-b-16">Período</div>
        <ul class="filter-list">
          <ng-container *ngIf="!account">
            <li *ngFor="let period of periodList; let i = index" (click)="selectPeriod(period, '')" class="m-b-4">
              <div class="body2 period p-12" [ngClass]="{'selected': periodSelected === period}">Últimos {{period}} dias
              </div>
            </li>
          </ng-container>

          <ng-container *ngIf="account">
            <li *ngFor="let period of monthList; let i = index" (click)="selectPeriod(0, period)" class="m-b-4">
              <div class="body2 period p-12" [ngClass]="{'selected': monthSelected === period}">
                Mês {{period}}
              </div>
            </li>
          </ng-container>
          <li class="date-picker">
            <mat-form-field appearance="outline" *ngIf="!account">
              <mat-label>Outros períodos</mat-label>
              <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
                <input readonly matStartDate placeholder="Data inicial" [(ngModel)]="startDate">
                <input readonly matEndDate (dateChange)="changeDate()" [(ngModel)]="endDate" placeholder="Data final">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="account">
              <mat-label>Outros períodos</mat-label>
              <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">
                <input readonly matStartDate (dateChange)="changeStartDate()" placeholder="Data inicial"
                  [(ngModel)]="startDate">
                <input readonly matEndDate (dateChange)="changeDate()" [(ngModel)]="endDate" placeholder="Data final">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </li>
        </ul>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="24px" class="m-t-16">
      <div (click)="cleanFilter()" class="clean-title">Limpar filtros</div>
      <div class="button-basic-primary" (click)="applyFilter()" style="white-space:nowrap;">
        <i class="fas fa-check"></i>
        <span>Aplicar filtros</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showFilterOptions" (click)="filterOptionsToogle()" class="filterActive"></div>