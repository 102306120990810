import { createReducer, on } from "@ngrx/store";
import { setAccountIndex, logoutSession, setApiKey, setClient, setSettings, setTenant, setTokens, setClientAccounts, setClientAccountSelected } from "./auth.actions";
import { initialState } from "./auth.state";
import { UserNotification, UserSettings } from "src/app/models/authentication/userSettings";

const _authReducer = createReducer(
  initialState,

  on(setTokens, (state, action) => {
    return { ...state, tokens: action.tokens }
  }),

  on(setClient, (state, action) => {
    return { ...state, client: action.client.client, managers: action.client.Atribuicoes ?? null, notifications: action.client.receivedNotifications }
  }),

  on(setClientAccounts, (state, action) => {
    return { ...state, clientAccounts: action.accounts }
  }),

  on(setClientAccountSelected, (state, action) => {
    const type = action.account.operatorConfigProfile.userHolderTaxId.length === 14 ? "PJ" : "PF"

    return {
      ...state, clientAccountSelected: {
        ...action.account,
        type,
      }
    }
  }),

  on(logoutSession, (state, action) => {
    return {
      ...state,
      client: null,
      tokens: null,
      isLogged: false,
      error: null,
      apiKey: null,
      tenant: null,
      settings: new UserSettings('', false, false, false, false, false, new UserNotification(false, false,
        false, false, false)),
      accountType: null,
      clientAccounts: [],
      notifications: [],
      clientAccountSelected: null,
      managers: null,
      linkedAccounts: [],
    }
  }),

  on(setApiKey, (state, action) => {
    return { ...state, apiKey: action.apiKey }
  }),

  on(setTenant, (state, action) => {
    return { ...state, tenant: action.tenant }
  }),

  on(setSettings, (state, action) => {
    return { ...state, settings:{ ...action.settings }}
  }),

  on(setAccountIndex, (state, action) => {
    return { ...state, accountType: action.account }
  })
)

export function AuthReducer(state: any, action: any) {
  return _authReducer(state, action);
}
