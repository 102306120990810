import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { DATE_FORMAT } from 'src/app/utils/consts';

export interface FilterEmit {
  selectedStatus: string;
  period: {
    start: string;
    end: string;
  };
  status: TypeSelect[] | null;
  account: TypeSelect | null;
  quantityFilters: number;
  periodSelected: number | null;
}

export interface TypeSelect {
  name: string;
  type: string;
  icon: string;
  color: string;
  checked: boolean;
  optionSelected: any;
}


@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit, AfterViewInit {

  showFilterOptions: boolean = false;
  
  monthSelected: string | null = null;


  filter: FilterEmit = {
    selectedStatus: '',
    status: null,
    account: null,
    period: {
      start: '',
      end: '',
    },
    periodSelected: null,
    quantityFilters: 0,
  };

  minDate = moment('2022-01-01').toDate();
  maxDate = moment().toDate();

  @Input() startDate: string =  moment().startOf('month').format(DATE_FORMAT);
  @Input() endDate: string = moment().format(DATE_FORMAT);
  @Input() periodSelected: number | null = null;

  @Input() situation: boolean = false;
  @Input() period: boolean = true;
  @Input() account: boolean = false;
  @Input() currentMonth: boolean = true;
  @Input() onlyPeriod: boolean = false;

  @Input() statusList: TypeSelect[] = [];
  @Input() accountList: TypeSelect[] = [];
  @Input() periodList: number[] = [];
  @Input() monthList: string[] = [];

  @Output() filterEmmit: EventEmitter<FilterEmit> = new EventEmitter();

  constructor() {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }

  filterOptionsToogle(): void {
    this.showFilterOptions = !this.showFilterOptions;
  }

  updateCheckboxStatus(event: any, index: any, filter_category: any) {
    if (filter_category === 'account') {
      const accountList = cloneDeep(this.accountList)
      accountList.forEach(a => a.checked = false);
      accountList[index].checked = event.checked;
      this.accountList = accountList;
    }

    if (filter_category === 'situation') {
      const statusList = cloneDeep(this.statusList);
      statusList[index].checked = event.checked;
      this.statusList = statusList
    }
  }

  selectPeriod(period: number, month: string): void {
    if (period > 0) {
      this.periodSelected = this.periodSelected === period ? null : period;

      const startDate = moment().subtract(this.periodSelected, 'days').format(DATE_FORMAT);
      const endDate = moment().format(DATE_FORMAT);

      this.startDate = startDate;
      this.endDate = endDate;
    }
    if (month) {
      this.monthSelected = this.monthSelected === month ? null : month;
      if (month.toLowerCase() === 'atual') {
        this.startDate = moment().startOf('month').format(DATE_FORMAT);
        this.endDate = moment().endOf('month').format(DATE_FORMAT);
      } else {
        const dateNow = moment().subtract(1, 'month');
        this.startDate = dateNow.startOf('month').format(DATE_FORMAT);
        this.endDate = dateNow.endOf('month').format(DATE_FORMAT);
      }
    }
  }

  changeDate(): void {
    this.periodSelected = null;
    this.monthSelected = null;
  }

  changeStartDate(): void {
    const selectedDate = moment(this.startDate);
    this.minDate = selectedDate.startOf('month').toDate();

    if (selectedDate.month() === moment().month()) {
      this.maxDate = moment().toDate();
    } else {
      this.maxDate = selectedDate.endOf('month').toDate();
    }

    this.monthSelected = null;
  }

  cleanFilter(): void {
    this.periodSelected = null;
    this.monthSelected = null;
    this.startDate = moment().startOf('month').format(DATE_FORMAT);
    this.endDate = moment().format(DATE_FORMAT);;

   const statusList =  cloneDeep(this.statusList) 
   statusList.forEach((a, i) => a.checked = false);
    this.statusList = statusList;
    
    this.accountList.forEach((a, i) => {
      if (i === 0) {
        a.checked = true
      } else {
        a.checked = false
      }
    });

    this.minDate = moment('2022-01-01').toDate();
    this.maxDate = moment().toDate();

    this.filter = {
      selectedStatus: '',
      status: statusList,
      account: this.accountList.find(a => a.checked === true) ?? null,
      period: {
        start: this.startDate,
        end: this.endDate,
      },
      periodSelected: null,
      quantityFilters: 1,
    };

  }

  applyFilter(): void {
    const quantityFilters = this.calculateQuantityFilters();
    const { startDate, endDate } = this.calculateDateRange();

    this.filter = {
      selectedStatus: this.statusList.filter(a => a.checked === true).map(a => a.name).join(", "),
      status: this.statusList ?? null,
      account: this.accountList.find(a => a.checked === true) ?? null,
      period: {
        start: startDate,
        end: endDate,

      },
      periodSelected: this.periodSelected,
      quantityFilters,
    };

    this.showFilterOptions = false;
    this.filterEmmit.emit(this.filter);
  }

  calculateQuantityFilters(): number {
    let quantityFilters = 0;
    quantityFilters += (this.startDate && this.endDate) || this.periodSelected ? 1 : 0;
    quantityFilters += this.statusList.filter(a => a.checked === true).length > 0 ? 1 : 0;
    quantityFilters += this.accountList.filter(a => a.checked === true).length > 0 ? 1 : 0;
    return quantityFilters;
  }

  calculateDateRange(): { startDate: string; endDate: string } {
    if (this.monthSelected !== null) {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
      }
    }
    if (this.periodSelected === null || (this.currentMonth && this.startDate !== '' && this.endDate !== '')) {
      return {
        startDate: this.startDate ? moment(this.startDate).format(DATE_FORMAT) : '',
        endDate: this.endDate ? moment(this.endDate).format(DATE_FORMAT) : '',
      };
    } else {

      return { startDate: this.startDate, endDate: this.endDate };
    }
  }
}
