import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemSettings } from 'src/app/models/settings/system-settings';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getApiUrl } from 'src/app/core/state/general-state/general.selectors';
import { getSettings } from 'src/app/core/state/auth-state/auth.selectors';
import { UserSettings } from 'src/app/models/authentication/userSettings';
import { cloneDeep } from 'lodash';
import { setSettings } from 'src/app/core/state/auth-state/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private syssettings: SystemSettings = {} as SystemSettings;

  apiUrl: string = '';
  settigs: UserSettings | null = null;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    this.store.select(getApiUrl).subscribe(apiUrl => this.apiUrl = apiUrl);
    this.store.select(getSettings).subscribe(settings => this.settigs = settings)
  }


  initConfigFile(): Observable<any> {
    let params = new HttpParams().set('ccf', 'true');

    return this.http.get<any>(this.apiUrl + '/v1/diletta/tenant/files/config', { params });
  }

  initConfigTenantFile(ccf: boolean): Observable<any> {
    let params = new HttpParams().set('ccf', ccf.toString().toLowerCase());

    return this.http.get<any>(this.apiUrl + '/v1/diletta/tenant/config', { params });
  }


  getLogo(): Observable<any> {
    const requestOptions: Object = { responseType: 'blob' }
    return this.http.get<any>(this.apiUrl + '/v1/diletta/tenant/files/logo', requestOptions);
  }


  getIncomeReports(year: string): Observable<any> {
    let params = new HttpParams().set('year', year);
    return this.http.get<any>(this.apiUrl + '/v1/diletta/banking/income-reports', { params });
  }

  dontShowDialog(): Observable<any> {
    const settigs = cloneDeep(this.settigs)
    if (settigs) {
      settigs!.notification_autorization = false;
    }


    var account = {
      settings: settigs
    }

    this.store.dispatch(setSettings({ settings: settigs }))
    return this.http.put(this.apiUrl + '/v1/diletta/account/settings', { account });
  }

  getAsJSON(): JSON {
    return JSON.parse(JSON.stringify(this.syssettings));
  }

  getAboutTXTData(){
    return this.http.get('../../../assets/files/version.txt', { responseType: 'text' })
  }

  get() {
    return this.syssettings;
  }

  set(value: SystemSettings) {
    this.syssettings = value;
  }
}
